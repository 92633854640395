<template>
  <section>
    <!-- <portal-target name="BreadCrumbs">
      <nav class="breadcrumb" aria-label="breadcrumbs">
        <ul>
          <router-link tag="li" to="/service"><a>Zgłoszenie serwisowe</a></router-link>
          <router-link tag="li" :to="{ name: 'ServiceDetails', params: { id: this.$route.params.id }}" class="is-active is-uppercase"><a>{{ name }}</a></router-link>
        </ul>   
      </nav>
    </portal-target> -->
    <div class="ym-toptitle-box">
      <fixed-header :threshold="0" fixedClass="ym-toptitle-fixed">
        <div id="toptitle" class="level ym-toptitle">
          <div class="level-left">
            <router-link
              tag="a"
              to="/service/"
              class="button xbtn is-medium is-transparent"
              ><icon name="x"></icon
            ></router-link>
            <div>
              <p>Numer zlecenia</p>
              <h2 class="title">{{ name }}</h2>
            </div>
            <div style="margin-left: 2rem">
              <p>Status</p>
              <h2 class="title" :style="statusColor()" v-if="order.status_id">
                {{ statusName().toUpperCase() }}
              </h2>
            </div>
          </div>
          <div v-if="isOnline" class="buttons level-right">
            <!-- <button @click="orderRemove" class="button is-medium is-light"><icon name="trash"></icon><span>Usuń</span></button> -->
            <!-- <a :href="order.pdf_protocol" target="_blank" class="button is-medium is-light"><icon name="download"></icon><div>PDF</div></a>  -->
            <router-link
              v-if="order.technical_id === user.id"
              tag="a"
              :to="{
                name: 'ServiceEdit',
                params: { id: parseInt(this.$route.params.id) },
              }"
              class="button is-medium is-black"
              ><icon name="edit"></icon><span>Edytuj</span></router-link
            >
            <!-- <a class="button is-medium is-dark"><icon name="check"></icon><span>Wybierz</span></a> -->
          </div>
        </div>
      </fixed-header>
    </div>
    <div
      :class="
        order && order.status_id === 20
          ? 'ym-whitebg pa40 mb-72'
          : 'ym-whitebg pa40 mb-24'
      "
    >
      <div class="columns is-mobile is-variable is-3">
        <div class="column is-4" v-if="order.number">
          <p class="label-small">Numer zlecenia</p>
          <p class="label is-uppercase">{{ order.number }}</p>
        </div>
        <div class="column is-4" v-if="order.created_at">
          <p class="label-small">Data przyjęcia</p>
          <p class="label is-uppercase">{{ order.created_at }}</p>
        </div>
        <div class="column is-4" v-if="order.status_id">
          <p class="label-small">Status zlecenia</p>
          <p class="label is-uppercase">{{ statusName().toUpperCase() }}</p>
        </div>
      </div>
      <div class="columns is-mobile is-variable is-3">
        <div class="column is-4" v-if="order.operator_name">
          <p class="label-small">Zlecenie przyjął</p>
          <p class="label is-uppercase">{{ order.operator_name }}</p>
        </div>
        <div class="column is-4" v-if="order.technical_name">
          <p class="label-small">Serwisant</p>
          <p class="label is-uppercase">{{ order.technical_name }}</p>
        </div>
        <div class="column is-4" v-if="order.repared_at">
          <p class="label-small">Data naprawy</p>
          <p class="label is-uppercase">{{ order.repared_at }}</p>
        </div>
      </div>
      <hr />
      <div class="columns is-mobile is-variable is-3">
        <div class="column is-4" v-if="order.customer_name">
          <p class="label-small">Klient</p>
          <p class="label is-uppercase">{{ order.customer_name }}</p>
        </div>
        <div class="column is-4" v-if="order.customer_address">
          <p class="label-small">Adres klienta</p>
          <p class="label is-uppercase">{{ order.customer_address }}</p>
        </div>
      </div>
      <div class="columns is-mobile is-variable is-3">
        <div class="column is-4" v-if="order.customer_phone">
          <p class="label-small">Telefon klienta</p>
          <p class="label is-uppercase">{{ order.customer_phone }}</p>
        </div>
        <div class="column is-4" v-if="order.customer_email">
          <p class="label-small">Email klienta</p>
          <p class="label is-uppercase">{{ order.customer_email }}</p>
        </div>
        <div class="column is-4" v-if="order.customer_nip">
          <p class="label-small">NIP klienta</p>
          <p class="label is-uppercase">{{ order.customer_nip }}</p>
        </div>
      </div>
      <hr />
      <div class="columns is-mobile is-variable is-3">
        <div class="column is-4" v-if="order.device_type">
          <p class="label-small">Typ maszyny</p>
          <p class="label is-uppercase">{{ order.device_type }}</p>
        </div>
        <div class="column is-4" v-if="order.device_name">
          <p class="label-small">Nazwa maszyny</p>
          <p class="label is-uppercase">{{ order.device_name }}</p>
        </div>
      </div>
      <div class="columns is-mobile is-variable is-3">
        <div class="column is-4" v-if="order.device_serial">
          <p class="label-small">Numer seryjny maszyny</p>
          <p class="label is-uppercase">{{ order.device_serial }}</p>
        </div>
        <div class="column is-4" v-if="order.device_address">
          <p class="label-small">Miejsce postoju maszyny</p>
          <p class="label is-uppercase">{{ order.device_address }}</p>
        </div>
      </div>
      <div class="columns is-mobile is-variable is-3">
        <div class="column is-4">
            <p class="label-small">Gwarancja</p>
            <p class="label is-uppercase">{{ order.is_warranty ? 'TAK' : 'NIE' }}</p>
        </div>
        <div class="column is-4" v-if="order.warranty_status_id !== 0">
            <p class="label-small">Status gwarancji</p>
            <p class="label is-uppercase">{{ warrantyName }}</p>
        </div>
      </div>
      <div class="columns is-mobile is-variable is-3">
        <div class="column is-4">
            <p class="label-small">NP</p>
            <p class="label is-uppercase">{{ order.is_np ? 'TAK' : 'NIE' }}</p>
        </div>
      </div>
      <div class="columns is-mobile is-variable is-3">
        <div class="column is-4">
              <p class="label-small">Liczba motogodzin</p>
              <p class="label is-uppercase">{{ order.device_hours }}</p>
        </div>          
        <div class="column is-4" v-if="order.service_type_id !== 0">
            <p class="label-small">Kategoria prac</p>
            <p class="label is-uppercase">{{ order.service_type_name }}</p>
        </div>
      </div>
      <div class="columns is-mobile is-variable is-3">
        <div class="column is-6" v-if="order.device_service_work">
          <p class="label-small">Czynności do wykonania</p>
          <p class="label is-uppercase">{{ order.device_service_work }}</p>
        </div>
      </div>
      <div class="columns is-mobile is-variable is-3">
        <div class="column is-6" v-if="order.operator_notes">
          <p class="label-small">Uwagi operatora</p>
          <p class="label is-uppercase">{{ order.operator_notes }}</p>
        </div>
      </div>
      <div v-if="order && order.status_id === 40">
        <hr />
        <div class="columns is-mobile is-variable is-6">
          <div class="column is-12" v-if="order.service_fault">
            <p class="label-small">Opis usterki</p>
            <p class="label is-uppercase">{{ order.service_fault }}</p>
          </div>
        </div>
        <div class="columns is-mobile is-variable is-6">
          <div class="column is-12" v-if="order.service_description">
            <p class="label-small">Opis wykonywanych czynności</p>
            <p class="label is-uppercase">{{ order.service_description }}</p>
          </div>
        </div>
        <div class="columns is-mobile is-variable is-6">
          <div class="column is-12" v-if="order.technical_service">
            <p class="label-small">Rodzaj czynności</p>
            <p class="label is-uppercase">{{ order.technical_service }}</p>
          </div>
        </div>
        <div class="columns is-mobile is-variable is-6">
          <div class="column is-12" v-if="order.technical_notes">
            <p class="label-small">Uwagi serwisanta</p>
            <p class="label is-uppercase">{{ order.technical_notes }}</p>
          </div>
        </div>
        <div class="columns is-mobile is-variable is-3">
          <div class="column is-3">
            <p class="label-small">Liczba ha</p>
            <p class="label is-uppercase">{{ order.device_area }}</p>
          </div>
          <div class="column is-3">
            <p class="label-small">Liczba bali</p>
            <p class="label is-uppercase">{{ order.device_bales }}</p>
          </div>
          <div class="column is-3">
            <p class="label-small">Liczba motogodzin</p>
            <p class="label is-uppercase">{{ order.device_hours }}</p>
          </div>
        </div>
        <div class="columns is-mobile is-variable is-3">
          <div class="column is-3">
            <p class="label-small">Płatne</p>
            <p class="label is-uppercase">
              {{ order.is_paid ? "TAK" : "NIE" }}
            </p>
          </div>
          <div class="column is-3">
            <p class="label-small">Handel</p>
            <p class="label is-uppercase">
              {{ order.is_trade ? "TAK" : "NIE" }}
            </p>
          </div>
          <div class="column is-3">
            <p class="label-small">Gwarancja</p>
            <p class="label is-uppercase">
              {{ order.is_warranty ? "TAK" : "NIE" }}
            </p>
          </div>
          <div class="column is-3">
            <p class="label-small">Usługi</p>
            <p class="label is-uppercase">
              {{ order.is_service ? "TAK" : "NIE" }}
            </p>
          </div>
        </div>
        <div
          class="columns is-mobile is-variable is-3"
          v-if="order.service_type_name"
        >
          <div class="column is-4">
            <p class="label-small">Czynności serwisowe</p>
            <p class="label is-uppercase">{{ order.service_type_name }}</p>
          </div>
        </div>
        <div
          class="columns is-multiline is-mobile is-variable is-3"
          v-if="order_technical.length > 0"
        >
          <div class="column is-12">
            <p class="label-small">Serwisanci asystujący</p>
            <div
              class="column is-3"
              v-for="(technical, index) in order_technical"
              :key="technical.id"
            >
              <p class="label is-uppercase">
                {{ index + 1 }}) {{ technical.firstname.toUpperCase() }}
                {{ technical.lastname.toUpperCase() }}
              </p>
            </div>
          </div>
        </div>
        <div
          class="columns is-multiline is-mobile is-variable is-3"
          v-if="order_commute.length > 0"
        >
          <div class="column is-12">
            <p class="label-small">Dojazd</p>
            <div class="ym-table ym-table__info">
              <div
                v-for="commute in order_commute"
                :key="commute.id"
                class="ym-tableItem ym-tableItem__prl"
              >
                <div>{{ commute.commuted_at }}</div>
                <div>
                  {{ commute.city_from.toUpperCase() }}
                  <!-- <small v-if="commute.time_from !== '00:00'">({{ commute.time_from }})</small> -->
                </div>
                <div>
                  {{ commute.city_to.toUpperCase() }}
                  <!-- <small v-if="commute.time_to !== '00:00'">({{ commute.time_to }})</small> -->
                </div>
                <div>{{ commute.distance }} km</div>
                <div>&nbsp;</div>
              </div>
            </div>
          </div>
        </div>
        <div
          class="columns is-multiline is-mobile is-variable is-3"
          v-if="order_work.length > 0"
        >
          <div class="column is-12">
            <p class="label-small">Godziny pracy</p>
            <div class="ym-table ym-table__info">
              <div
                v-for="work in order_work"
                :key="work.id"
                class="ym-tableItem ym-tableItem__prl"
              >
                <div>{{ work.worked_at }}</div>
                <div>{{ work.time_from }} - {{ work.time_to }}</div>
                <div>{{ work.minutes }} x{{ work.workers }}</div>
                <div>{{ work.workers_time }}</div>
                <div></div>
                <div></div>
              </div>
            </div>
          </div>
        </div>
        <div
          class="columns is-multiline is-mobile is-variable is-3"
          v-if="files.length > 0"
        >
          <span
            v-for="(file, index) in files"
            :key="index"
            class="column is-12"
          >
            <img :src="file.url" style="margin-bottom: 16px" />
          </span>
        </div>
        <div class="columns is-mobile is-variable is-3" v-if="order.signature">
          <div class="column is-4">
            <p class="label-small">Podpis</p>
            <img :src="order.signature_url" />
          </div>
        </div>
      </div>
    </div>
    <ValidationObserver ref="observer" v-slot="{ handleSubmit, errors }">
      <form class="login" @submit.prevent="handleSubmit(onSubmit)">
        <div class="mb-72" v-if="order && order.status_id === 30">
          <div class="ym-toptitle-box">
            <div id="toptitle" class="level ym-toptitle">
              <div class="level-left">
                <h2 class="title">Protokół serwisowy</h2>
              </div>
            </div>
          </div>
          <div
            :class="
              errors['service_fault'] && errors['service_fault'].length > 0
                ? 'columns is-mobile is-variable is-3 is-multiline v-field v-error'
                : 'columns is-mobile is-variable is-3 is-multiline'
            "
          >
            <div class="column ym-column is-3">
              <div class="ym-label">
                <label for="f_company_full">Opis usterki</label>
                <span
                  v-if="
                    errors['service_fault'] &&
                      errors['service_fault'].length > 0
                  "
                  class="help is-danger"
                  >{{ errors["service_fault"][0] }}</span
                >
              </div>
            </div>
            <div class="column ym-column is-9">
              <div class="field">
                <div class="control is-medium">
                  <ValidationProvider rules="required" name="service_fault">
                    <textarea
                      name="service_fault"
                      v-model="protocol.service_fault"
                      class="input is-medium is-uppercase"
                    />
                  </ValidationProvider>
                </div>
              </div>
            </div>
          </div>
          <div
            :class="
              errors['service_description'] &&
              errors['service_description'].length > 0
                ? 'columns is-mobile is-variable is-3 is-multiline v-field v-error'
                : 'columns is-mobile is-variable is-3 is-multiline'
            "
          >
            <div class="column ym-column is-3">
              <div class="ym-label">
                <label for="f_company_full">Opis wykonywanych czynności</label>
                <span
                  v-if="
                    errors['service_description'] &&
                      errors['service_description'].length > 0
                  "
                  class="help is-danger"
                  >{{ errors["service_description"][0] }}</span
                >
              </div>
            </div>
            <div class="column ym-column is-9">
              <div class="field">
                <div class="control is-medium">
                  <ValidationProvider
                    rules="required"
                    name="service_description"
                  >
                    <textarea
                      name="service_description"
                      v-model="protocol.service_description"
                      class="input is-medium is-uppercase"
                    />
                  </ValidationProvider>
                </div>
              </div>
            </div>
          </div>
          <div
            :class="
              errors['technical_service'] &&
              errors['technical_service'].length > 0
                ? 'columns is-mobile is-variable is-3 is-multiline v-field v-error'
                : 'columns is-mobile is-variable is-3 is-multiline'
            "
          >
            <div class="column ym-column is-3">
              <div class="ym-label">
                <label for="f_company_full">Rodzaj czynności</label>
                <span
                  v-if="
                    errors['technical_service'] &&
                      errors['technical_service'].length > 0
                  "
                  class="help is-danger"
                  >{{ errors["technical_service"][0] }}</span
                >
              </div>
            </div>
            <div class="column ym-column is-9">
              <div class="field">
                <div class="control is-medium">
                  <ValidationProvider rules="required" name="technical_service">
                    <textarea
                      name="technical_service"
                      v-model="protocol.technical_service"
                      class="input is-medium is-uppercase"
                    />
                  </ValidationProvider>
                </div>
              </div>
            </div>
          </div>
          <div
            :class="
              errors['technical_notes'] && errors['technical_notes'].length > 0
                ? 'columns is-mobile is-variable is-3 is-multiline v-field v-error'
                : 'columns is-mobile is-variable is-3 is-multiline'
            "
          >
            <div class="column ym-column is-3">
              <div class="ym-label">
                <label for="f_company_full">Uwagi serwisanta</label>
                <span
                  v-if="
                    errors['technical_notes'] &&
                      errors['technical_notes'].length > 0
                  "
                  class="help is-danger"
                  >{{ errors["technical_notes"][0] }}</span
                >
              </div>
            </div>
            <div class="column ym-column is-9">
              <div class="field">
                <div class="control is-medium">
                  <ValidationProvider name="technical_notes">
                    <textarea
                      name="technical_notes"
                      v-model="protocol.technical_notes"
                      class="input is-medium is-uppercase"
                    />
                  </ValidationProvider>
                </div>
              </div>
            </div>
          </div>
          <div
            :class="
              errors['device_hours'] && errors['device_hours'].length > 0
                ? 'columns is-mobile is-variable is-3 is-multiline v-field v-error'
                : 'columns is-mobile is-variable is-3 is-multiline'
            "
          >
            <div class="column ym-column is-3">
              <div class="ym-label">
                <label for="f_company_full">Aktualizacja motogodzin</label>
                <span
                  v-if="
                    errors['device_hours'] && errors['device_hours'].length > 0
                  "
                  class="help is-danger"
                  >{{ errors["device_hours"][0] }}</span
                >
              </div>
            </div>
            <div class="column ym-column is-9">
              <div class="field">
                <div class="control is-medium">
                  <ValidationProvider
                    rules="required|min_value:1"
                    name="device_hours"
                  >
                    <input
                      name="device_hours"
                      type="number"
                      v-model="protocol.device_hours"
                      class="input is-medium is-uppercase"
                    />
                  </ValidationProvider>
                </div>
              </div>
            </div>
          </div>
          <div
            :class="
              errors['device_serial'] && errors['device_serial'].length > 0
                ? 'columns is-mobile is-variable is-3 is-multiline v-field v-error'
                : 'columns is-mobile is-variable is-3 is-multiline'
            "
          >
            <div class="column ym-column is-3">
              <div class="ym-label">
                <label for="f_company_full"
                  >Aktualizacja numeru serynego maszyny</label
                >
                <span
                  v-if="
                    errors['device_serial'] &&
                      errors['device_serial'].length > 0
                  "
                  class="help is-danger"
                  >{{ errors["device_serial"][0] }}</span
                >
              </div>
            </div>
            <div class="column ym-column is-9">
              <div class="field">
                <div class="control is-medium">
                  <ValidationProvider rules="required" name="device_serial">
                    <input
                      name="device_serial"
                      type="text"
                      v-model="protocol.device_serial"
                      class="input is-medium is-uppercase"
                    />
                  </ValidationProvider>
                </div>
              </div>
            </div>
          </div>
          <div
            :class="
              errors['device_area'] && errors['device_area'].length > 0
                ? 'columns is-mobile is-variable is-3 is-multiline v-field v-error'
                : 'columns is-mobile is-variable is-3 is-multiline'
            "
          >
            <div class="column ym-column is-3">
              <div class="ym-label">
                <label for="f_company_full">Liczba ha</label>
                <span
                  v-if="
                    errors['device_area'] && errors['device_area'].length > 0
                  "
                  class="help is-danger"
                  >{{ errors["device_area"][0] }}</span
                >
              </div>
            </div>
            <div class="column ym-column is-9">
              <div class="field">
                <div class="control is-medium">
                  <ValidationProvider rules="required" name="device_area">
                    <input
                      name="device_area"
                      type="number"
                      v-model="protocol.device_area"
                      class="input is-medium is-uppercase"
                    />
                  </ValidationProvider>
                </div>
              </div>
            </div>
          </div>
          <div
            :class="
              errors['device_bales'] && errors['device_bales'].length > 0
                ? 'columns is-mobile is-variable is-3 is-multiline v-field v-error'
                : 'columns is-mobile is-variable is-3 is-multiline'
            "
          >
            <div class="column ym-column is-3">
              <div class="ym-label">
                <label for="f_company_full">Liczba bali</label>
                <span
                  v-if="
                    errors['device_bales'] && errors['device_bales'].length > 0
                  "
                  class="help is-danger"
                  >{{ errors["device_bales"][0] }}</span
                >
              </div>
            </div>
            <div class="column ym-column is-9">
              <div class="field">
                <div class="control is-medium">
                  <ValidationProvider rules="required" name="device_bales">
                    <input
                      name="device_bales"
                      type="number"
                      v-model="protocol.device_bales"
                      class="input is-medium is-uppercase"
                    />
                  </ValidationProvider>
                </div>
              </div>
            </div>
          </div>
          <div
            :class="
              errors['is_paid'] && errors['is_paid'].length > 0
                ? 'columns is-mobile is-variable is-3 is-multiline v-field v-error'
                : 'columns is-mobile is-variable is-3 is-multiline'
            "
          >
            <div class="column ym-column totop is-3">
              <div class="ym-label">
                <label for="f_dm_company">Płatne</label>
                <span
                  v-if="errors['is_paid'] && errors['is_paid'].length > 0"
                  class="help is-danger"
                  >{{ errors["is_paid"][0] }}</span
                >
              </div>
            </div>
            <div class="column ym-column is-9">
              <div class="ym-rowbor">
                <div class="level ym-rowbor-item">
                  <div class="ym-radios w150px">
                    <label>
                      <ValidationProvider rules="required" name="is_paid">
                        <input
                          type="radio"
                          name="is_paid"
                          v-model="protocol.is_paid"
                          :value="true"
                        />
                        <span>Tak</span>
                      </ValidationProvider>
                    </label>
                    <label>
                      <ValidationProvider rules="required" name="is_paid">
                        <input
                          type="radio"
                          name="is_paid"
                          v-model="protocol.is_paid"
                          :value="false"
                        />
                        <span>Nie</span>
                      </ValidationProvider>
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            :class="
              errors['is_trade'] && errors['is_trade'].length > 0
                ? 'columns is-mobile is-variable is-3 is-multiline v-field v-error'
                : 'columns is-mobile is-variable is-3 is-multiline'
            "
          >
            <div class="column ym-column totop is-3">
              <div class="ym-label">
                <label for="f_dm_company">Handel</label>
                <span
                  v-if="errors['is_trade'] && errors['is_trade'].length > 0"
                  class="help is-danger"
                  >{{ errors["is_trade"][0] }}</span
                >
              </div>
            </div>
            <div class="column ym-column is-9">
              <div class="ym-rowbor">
                <div class="level ym-rowbor-item">
                  <div class="ym-radios w150px">
                    <label>
                      <ValidationProvider rules="required" name="is_trade">
                        <input
                          type="radio"
                          name="is_trade"
                          v-model="protocol.is_trade"
                          :value="true"
                        />
                        <span>Tak</span>
                      </ValidationProvider>
                    </label>
                    <label>
                      <ValidationProvider rules="required" name="is_trade">
                        <input
                          type="radio"
                          name="is_trade"
                          v-model="protocol.is_trade"
                          :value="false"
                        />
                        <span>Nie</span>
                      </ValidationProvider>
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            :class="
              errors['is_warranty'] && errors['is_warranty'].length > 0
                ? 'columns is-mobile is-variable is-3 is-multiline v-field v-error'
                : 'columns is-mobile is-variable is-3 is-multiline'
            "
          >
            <div class="column ym-column totop is-3">
              <div class="ym-label">
                <label for="f_dm_company">Gwarancja</label>
                <span
                  v-if="
                    errors['is_warranty'] && errors['is_warranty'].length > 0
                  "
                  class="help is-danger"
                  >{{ errors["is_warranty"][0] }}</span
                >
              </div>
            </div>
            <div class="column ym-column is-9">
              <div class="ym-rowbor">
                <div class="level ym-rowbor-item">
                  <div class="ym-radios w150px">
                    <label>
                      <ValidationProvider rules="required" name="is_warranty">
                        <input
                          type="radio"
                          name="is_warranty"
                          v-model="protocol.is_warranty"
                          :value="true"
                          :disabled="isWarrantyDisabled"
                        />
                        <span>Tak</span>
                      </ValidationProvider>
                    </label>
                    <label>
                      <ValidationProvider rules="required" name="is_warranty">
                        <input
                          type="radio"
                          name="is_warranty"
                          v-model="protocol.is_warranty"
                          :value="false"
                          :disabled="isWarrantyDisabled"
                        />
                        <span>Nie</span>
                      </ValidationProvider>
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            :class="
              errors['is_service'] && errors['is_service'].length > 0
                ? 'columns is-mobile is-variable is-3 is-multiline v-field v-error'
                : 'columns is-mobile is-variable is-3 is-multiline'
            "
          >
            <div class="column ym-column totop is-3">
              <div class="ym-label">
                <label for="f_dm_company">Usługi</label>
                <span
                  v-if="errors['is_service'] && errors['is_service'].length > 0"
                  class="help is-danger"
                  >{{ errors["is_service"][0] }}</span
                >
              </div>
            </div>
            <div class="column ym-column is-9">
              <div class="ym-rowbor">
                <div class="level ym-rowbor-item">
                  <div class="ym-radios w150px">
                    <label>
                      <ValidationProvider rules="required" name="is_service">
                        <input
                          type="radio"
                          name="is_service"
                          v-model="protocol.is_service"
                          :value="true"
                        />
                        <span>Tak</span>
                      </ValidationProvider>
                    </label>
                    <label>
                      <ValidationProvider rules="required" name="is_service">
                        <input
                          type="radio"
                          name="is_service"
                          v-model="protocol.is_service"
                          :value="false"
                        />
                        <span>Nie</span>
                      </ValidationProvider>
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            :class="
              errors['order_technical'] && errors['order_technical'].length > 0
                ? 'columns is-mobile is-variable is-3 is-multiline v-field v-error'
                : 'columns is-mobile is-variable is-3 is-multiline'
            "
          >
            <div class="column ym-column totop is-3">
              <div class="ym-label">
                <label for="f_dm_company">Serwisanci asystujący</label>
                <span
                  v-if="
                    errors['order_technical'] &&
                      errors['order_technical'].length > 0
                  "
                  class="help is-danger"
                  >{{ errors["order_technical"][0] }}</span
                >
              </div>
            </div>
            <div class="column is-9">
              <div
                v-if="order_technical.length > 0"
                style="margin-bottom: 16px;"
              >
                <b-field
                  v-for="(technical, index) in order_technical"
                  :key="technical.id"
                  grouped
                >
                  <b-field expanded
                    ><input
                      class="input  is-uppercase"
                      type="text"
                      v-model="technical.firstname"
                  /></b-field>
                  <b-field expanded
                    ><input
                      class="input  is-uppercase"
                      type="text"
                      v-model="technical.lastname"
                    /><!-- <small v-if="commute.time_from !== '00:00'">({{ commute.time_from }})</small> --></b-field
                  >
                  <div class="ym-tableItemWrap">
                    <div
                      class="button"
                      @click="removeOrderTechnical(technical.id, index, technical)"
                    >
                      <icon name="x"></icon>
                    </div>
                    <div class="button" @click="editOrderTechnical(technical, index)">
                      <icon name="check"></icon>
                    </div>
                  </div>
                </b-field>
              </div>

              <b-field grouped>
                <b-field expanded>
                  <input
                    name="order_technical_firstname"
                    type="text"
                    v-model="order_technical_item.firstname"
                    placeholder="Imię"
                    class="input is-medium is-uppercase"
                  />
                </b-field>
                <b-field expanded>
                  <input
                    name="order_technical_lastname"
                    type="text"
                    v-model="order_technical_item.lastname"
                    placeholder="Nazwisko"
                    class="input is-medium is-uppercase"
                  />
                </b-field>
                <b-field expanded
                  ><div
                    type="button"
                    @click="addOrderTechnical"
                    class="button is-medium is-dark"
                  >
                    dodaj
                  </div></b-field
                >
              </b-field>
            </div>
            <ValidationProvider name="order_technical">
              <input
                type="hidden"
                name="order_technical"
                v-model="order_technical"
              />
            </ValidationProvider>
          </div>
          <div
            :class="
              errors['order_commute'] && errors['order_commute'].length > 0
                ? 'columns is-mobile is-variable is-3 is-multiline v-field v-error'
                : 'columns is-mobile is-variable is-3 is-multiline'
            "
          >
            <div class="column ym-column totop is-8">
              <div class="ym-label">
                <label for="f_dm_company">Dojazd</label>
                <span
                  v-if="
                    errors['order_commute'] &&
                      errors['order_commute'].length > 0
                  "
                  class="help is-danger"
                  >{{ errors["order_commute"][0] }}</span
                >
              </div>
            </div>
            <div
              class="column is-4"
              style="display: flex; align-items: center; justify-content: flex-end;"
            >
              <div class="ym-label">
                <label for="f_dm_company">{{ commuteDistanceSummary() }}</label>
              </div>
            </div>
            <div class="column is-12">
              <div
                v-if="order_commute.length > 0"
                class="ym-table ym-table__info"
              >
                <div
                  v-for="(commute, index) in order_commute"
                  :key="commute.id"
                  class="ym-tableItem ym-tableItem__prl"
                >
                  <div>
                    <template v-if="commute.commuted_at">{{ commute.commuted_at }}</template>
                    <template v-else>{{ new Date(commute.date).toLocaleDateString() }}</template>
                  </div>
                  <div>
                    {{ commute.city_from.toUpperCase() }}
                    <!-- <small v-if="commute.time_from !== '00:00'">({{ commute.time_from }})</small> -->
                  </div>
                  <div>
                    {{ commute.city_to.toUpperCase() }}
                    <!-- <small v-if="commute.time_to !== '00:00'">({{ commute.time_to }})</small> -->
                  </div>
                  <div>{{ commute.distance }} km</div>
                  <div @click="removeCommuteItem(commute.id, index, commute)">
                    <icon name="x"></icon>
                  </div>
                </div>
              </div>
              <b-field grouped>
                <b-field expanded>
                  <b-datepicker
                    :mobile-native="false"
                    name="order_commute_date"
                    v-model="order_commute_item.commuted_at"
                    placeholder="Data"
                    icon="calendar-today"
                    :first-day-of-week="1"
                    position="is-top-right"
                    trap-focus
                  >
                  </b-datepicker>
                </b-field>
                <b-field expanded>
                  <input
                    name="order_commute_from"
                    type="text"
                    v-model="order_commute_item.city_from"
                    placeholder="Dojazd z"
                    class="input is-medium is-uppercase"
                  />
                </b-field>
                <!-- <b-field expanded>
                    <b-timepicker size="is-medium" v-model="order_commute_item.time_from" placeholder="Od"></b-timepicker>
                  </b-field> -->
                <b-field expanded>
                  <input
                    name="order_commute_to"
                    type="text"
                    v-model="order_commute_item.city_to"
                    placeholder="Dojazd do"
                    class="input is-medium is-uppercase"
                  />
                </b-field>
                <!-- <b-field expanded>
                    <b-timepicker size="is-medium" v-model="order_commute_item.time_to" placeholder="Do"></b-timepicker>
                  </b-field> -->
                <b-field expanded>
                  <input
                    name="order_commute_distance"
                    type="number"
                    v-model="order_commute_item.distance"
                    placeholder=" km"
                    class="input is-medium is-uppercase"
                  />
                </b-field>
                <b-field expanded
                  ><div
                    type="button"
                    @click="addCommuteItem"
                    class="button is-medium is-dark"
                  >
                    dodaj
                  </div></b-field
                >
              </b-field>
            </div>
            <ValidationProvider rules="required" name="order_commute">
              <input
                type="hidden"
                name="order_commute"
                v-model="order_commute"
              />
            </ValidationProvider>
          </div>
          <div
            :class="
              errors['order_work'] && errors['order_work'].length > 0
                ? 'columns is-mobile is-variable is-3 is-multiline v-field v-error'
                : 'columns is-mobile is-variable is-3 is-multiline'
            "
          >
            <div class="column ym-column totop is-8">
              <div class="ym-label">
                <label for="f_dm_company">Godziny pracy</label>
                <span
                  v-if="errors['order_work'] && errors['order_work'].length > 0"
                  class="help is-danger"
                  >{{ errors["order_work"][0] }}</span
                >
              </div>
            </div>
            <div
              class="column is-4"
              style="display: flex; align-items: center; justify-content: flex-end;"
            >
              <div class="ym-label">
                <label
                  for="f_dm_company"
                  v-if="order && order.work_workers_time !== `00:00`"
                  >{{ order.work_workers_time }}</label
                >
              </div>
            </div>
            <div class="column is-12">
              <div v-if="order_work.length > 0" class="ym-table ym-table__info">
                <div
                  v-for="(work, index) in order_work"
                  :key="work.id"
                  class="ym-tableItem ym-tableItem__prl"
                >
                  <div>
                    <template v-if="work.worked_at">{{ work.worked_at }}</template>
                    <template v-else>{{ new Date(work.date).toLocaleDateString() }}</template>
                  </div>
                  <div>
                    <template v-if="work.time">{{ work.time_from }} - {{ work.time_to }}</template>
                    <template v-else>{{ new Date(work.time_from).toLocaleTimeString() }} - {{ new Date(work.time_to).toLocaleTimeString() }}</template>
                  </div>
                  <div>
                    <span v-if="work.minutes">{{ work.minutes }}</span> x{{ work.workers }}
                  </div>
                  <div>
                    <template v-if="work.workers_time">{{ work.workers_time }}</template>
                  </div>
                  <div></div>
                  <div @click="removeWorkItem(work.id, index, work)">
                    <icon name="x"></icon>
                  </div>
                </div>
              </div>
              <b-field grouped>
                <b-field expanded>
                  <b-datepicker
                    :mobile-native="false"
                    name="order_work_date"
                    v-model="order_work_item.date"
                    placeholder="Data"
                    icon="calendar-today"
                    :first-day-of-week="1"
                    position="is-top-right"
                    trap-focus
                  >
                  </b-datepicker>
                </b-field>
                <b-field expanded>
                  <b-timepicker
                    size="is-medium"
                    v-model="order_work_item.time_from"
                    placeholder="Od"
                  ></b-timepicker>
                </b-field>
                <b-field expanded>
                  <b-timepicker
                    size="is-medium"
                    v-model="order_work_item.time_to"
                    placeholder="Do"
                  ></b-timepicker>
                </b-field>
                <b-field expanded>
                  <input
                    name="order_work_distance"
                    type="text"
                    v-model="order_work_item.workers"
                    placeholder="Liczba serwisantów"
                    class="input is-medium is-uppercase"
                  />
                </b-field>
                <b-field expanded
                  ><div
                    type="button"
                    @click="addWorkItem"
                    class="button is-medium is-dark"
                  >
                    dodaj
                  </div></b-field
                >
              </b-field>
            </div>
            <ValidationProvider rules="required" name="order_work">
              <input type="hidden" name="order_work" v-model="order_work" />
            </ValidationProvider>
          </div>
          <div
            :class="errors['files'] && errors['files'].length > 0 ? 'columns is-mobile is-variable is-3 is-multiline v-field v-error' : 'columns is-mobile is-variable is-3 is-multiline'"
          >
            <div class="column ym-column totop is-12">
              <div class="ym-label">
                <label for="f_dm_company">Zdjęcie</label>
                <span v-if="errors['files'] && errors['files'].length > 0"
                  class="help is-danger">{{ errors['files'][0] }}</span>  
              </div>
            </div>
            <div class="column is-12" style="margin-bottom: 24px">
              <ValidationProvider :rules="protocol.is_warranty && files.length === 0 ? 'required' : ''" name="files">  
                <b-upload
                  @input="handleFileChange(file)"
                  accept="image/*"
                  v-model="file"
                  v-if="files.length < 3"
                >
                  <section class="section">
                    <div class="content has-text-centered">
                      <p>
                        <b-icon icon="upload" size="is-large"> </b-icon>
                      </p>
                      <p>Dodaj zdjęcie</p>
                    </div>
                  </section>
                </b-upload>
              </ValidationProvider>
            </div>
            <div class="column is-12">
              <div class="columns is-multiline">
                <span
                  v-for="(file, index) in files"
                  :key="index"
                  class="column is-4"
                >
                  <img v-if="file.url" :src="file.url" />
                  <img v-else :src="file.image_stream" alt="">
                  <div class="buttons">
                    <b-button
                      @click="removeOrderImage(file.id, index, file)"
                      type="is-dark"
                      expanded
                    >
                      <span>Usuń zdjęcie</span>
                    </b-button>
                  </div>
                </span>
              </div>
            </div>
          </div>
          <div
            :class="
              errors['signature'] && errors['signature'].length > 0
                ? 'columns is-mobile is-variable is-3 is-multiline v-field v-error'
                : 'columns is-mobile is-variable is-3 is-multiline'
            "
          >
            <div class="column ym-column totop is-10">
              <div class="ym-label">
                <label for="f_dm_company">Podpis klienta</label>
              </div>
            </div>
            <div class="column is-2">
              <div v-show="signature" id="clearDraw" class="button is-light">
                <icon name="x"></icon><span>wyczyść</span>
              </div>
            </div>
            <div class="column is-12">
              <div id="drawContainer" class="draw-container"></div>
              <!-- <span v-if="errors['signature'] && errors['signature'].length > 0"
                class="help is-danger">{{ errors['signature'][0] }}</span>   -->
            </div>
            <ValidationProvider rules="required" name="signature">
              <input
                type="hidden"
                name="signature"
                ref="sign"
                v-model="signature"
              />
            </ValidationProvider>
          </div>
        </div>
        <div class="confirmButtons" v-if="isOnline">
          <router-link
            tag="a"
            :to="{ name: 'Service' }"
            class="button is-medium is-light"
            >Zamknij</router-link
          >
          <div
            class="button is-medium is-dark"
            v-if="order && order.status_id === 20"
            @click="changeOrderStatus(30)"
          >
            Przyjmij zlecenie
          </div>
          <button
            type="submit"
            class="button is-medium is-primary"
            v-if="order && order.status_id === 30"
          >
            <icon name="check"></icon><span>Zapisz i zakończ</span>
          </button>
        </div>
        <div class="confirmButtons" v-if="isOffline && order && order.status_id === 30">
          <div>&nbsp;</div>
          <button
            type="submit"
            class="button is-medium is-primary"
          >
            <icon name="check"></icon><span>Zapisz</span>
          </button>
        </div>
      </form>
    </ValidationObserver>
    <div v-if="protocolToSend" class="offlinemodal">
      <div class="offlinemodal-overlay"></div>
      <div class="offlinemodal-content">
        <h3>Brak dostępu do internetu.</h3>
        <div class="lds-dual-ring"></div>
        <p><b>Nie opuszczaj aplikacji.</b></p>
        <p>Formularz zostanie wysłany, gdy urządzenie znajdzie się w trybie online.</p>
      </div>
    </div>
    <div v-if="protocolToSendAfterLogout" class="offlinemodal">
      <div class="offlinemodal-overlay"></div>
      <div class="offlinemodal-content">
        <h3>Wysyłanie formularza.</h3>
        <div class="lds-dual-ring"></div>
        <p><b>Nie opuszczaj aplikacji.</b></p>
        <p>Trwa wysyłanie formularza.</p>
      </div>
    </div>
  </section>
</template>

<script>
import Konva from "konva";
import FixedHeader from "vue-fixed-header";
import { mapActions, mapGetters } from "vuex";
import YMmodal from "@/components/Modal.vue";
import { ValidationObserver, ValidationProvider } from "vee-validate";

export default {
  name: "ServiceDetails",
  components: {
    FixedHeader,
    ValidationObserver,
    ValidationProvider,
  },
  data: function() {
    return {
      warrantyStatus: [],
      order: {},
      protocol: {},
      serviceType: null,
      order_commute: [],
      order_commute_item: {
        commuted_at: new Date(),
        city_from: null,
        city_to: null,
        distance: null,
      },
      order_work: [],
      order_work_item: {
        date: new Date(),
        time_from: null,
        time_to: null,
        workers: 1,
      },
      captionSize: {
        width: 800,
        height: 300,
      },
      canvas: document.createElement("canvas"),
      isPaint: false,
      lastPointerPosition: null,
      signature: null,
      mode: "brush",
      stage: null,
      file: null,
      files: [],
      order_technical: [],
      order_technical_item: {
        firstname: null,
        lastname: null,
      },
      order_offline: {
        submit: false,
        order: {},
        protocol: {},
        order_technical: [],
        order_technical_remove: [],
        order_image: [],
        order_image_remove: [],
        order_work: [],
        order_work_remove: [],
        order_commute: [],
        order_commute_remove: [],
      },
      // online: navigator.onLine,
      isWarrantyDisabled: false,
      protocolToSend: false,
      protocolToSendAfterLogout: false,
    };
  },
  watch: {
    isOnline(newValue) {
      if (newValue && this.protocolToSend) {
        this.onOfflineSubmit()
      }
    },
    protocol: {
      handler (newData) {
        this.order_offline.protocol = newData
        this.storeOrderOffline()
      },
      deep: true
    }    
  },
  mounted() {
    const offlineProtocol = JSON.parse(localStorage.getItem('agro-order-offline'))
    if (offlineProtocol && offlineProtocol.submit) {
      document.querySelector('html').classList.add('overflow-y')
      this.order_offline = offlineProtocol
      this.order = offlineProtocol.order
      this.protocol = offlineProtocol.protocol
      this.protocolToSendAfterLogout = true
      this.onOfflineSubmit()
    } else if (this.isOnline) {
      this.getOrder(true);
      this.getServiceType();
      this.getCommuteItems();
      this.getWorkItems();
      this.getOrderImage();
      this.getOrderTechnical();
      this.getWarrantyStatus();
    }   
    // window.addEventListener("online", this.onchange);
    // window.addEventListener("offline", this.onchange);
    // this.onchange();
  },
  beforeDestroy() {
    // window.removeEventListener("online", this.onchange);
    // window.removeEventListener("offline", this.onchange);
  },
  computed: {
    ...mapGetters({
      user: "auth/user",
    }),
    name: function() {
      return this.order.number;
    },
    warrantyName() {
      const status = this.warrantyStatus.find(item => item.id === this.order.warranty_status_id)
      return status?.name
    }    
  },
  methods: {
    ...mapActions({
      getOrderCall: "orders/getOrder",
      removeOrderCall: "orders/removeOrder",
      changeOrderStatusCall: "orders/changeOrderStatus",
      getServiceTypeCall: "orders/serviceType",
      postCommuteItemsCall: "orders/postCommuteItems",
      getCommuteItemsCall: "orders/getCommuteItems",
      removeCommuteItemsCall: "orders/removeCommuteItems",
      postWorkItemsCall: "orders/postWorkItems",
      getWorkItemsCall: "orders/getWorkItems",
      removeWorkItemsCall: "orders/removeWorkItems",
      postOrder: "orders/postOrder",
      disabledSidebar: "setDisabledSidebar",
      getOrderImageCall: "orders/getOrderImage",
      postOrderImage: "orders/postOrderImage",
      removeOrderImageCall: "orders/removeOrderImage",
      getOrderTechnicalCall: "orders/getOrderTechnical",
      postOrderTechnicalCall: "orders/postOrderTechnical",
      editOrderTechnicalCall: "orders/editOrderTechnical",
      removeOrderTechnicalCall: "orders/removeOrderTechnical",
      getWarrantyStatusCall: "orders/warrantyStatus",
    }),
    getWarrantyStatus() {
      this.getWarrantyStatusCall()
        .then((resp) => (this.warrantyStatus = resp))
        .catch((error) => {
          this.serviceType = {};
          this.$buefy.toast.open({
            duration: 5000,
            message: error.data.error_description,
            position: "is-bottom",
            type: "is-danger",
          });
        });
    },     
    // onchange() {
    //   this.online = navigator.onLine;
    //   this.$emit(this.online ? "online" : "offline");
    // },
    getOrder(once = false) {
      this.getOrderCall({ id: this.$route.params.id })
        .then((resp) => (this.order = resp))
        .then(() => {
          this.order_offline.order = this.order
          if (once) {
            this.protocol.device_hours = this.order.device_hours;
            this.protocol.device_serial = this.order.device_serial;
            this.protocol.is_warranty = this.order.is_warranty;
            if(this.protocol.is_warranty) {
              this.isWarrantyDisabled = true
            }
          }

          if (this.order.status_id === 30) {
            this.draw();
          }
        })
        .catch((error) => {
          this.order = {};
          this.$buefy.toast.open({
            duration: 5000,
            message: error ? error.data.error_description : "",
            position: "is-bottom",
            type: "is-danger",
          });
        });
    },
    getServiceType() {
      this.getServiceTypeCall()
        .then((resp) => (this.serviceType = resp))
        .catch((error) => {
          this.serviceType = {};
          this.$buefy.toast.open({
            duration: 5000,
            message: error.data.error_description,
            position: "is-bottom",
            type: "is-danger",
          });
        });
    },
    orderRemove() {
      this.$buefy.modal.open({
        parent: this,
        component: YMmodal,
        hasModalCard: true,
        props: {
          title: "Czy na pewno chcesz usunąć zlecenie?",
          content: `<span class='is-danger'>Usunięcia zlecenia nie można cofnąć.</span> Zlecenie pozostanie w utworzonych do tej pory protokołach.`,
          cancel: "Anuluj",
          ymHasIcon: true,
          ymIcon: "trash",
          submit: `<span class="mal50i">Usuń</span>`,
          ymConfirm: true,
          ymOnConfirm: () => {
            this.removeOrderCall({ id: this.$route.params.id })
              .then(({ data }) => {
                document.querySelector(".is-cancel").click();
                this.$router.push({ name: "Service" });
                this.$buefy.toast.open({
                  duration: 5000,
                  message: data.alert[1],
                  type: "is-success",
                  position: "is-bottom",
                });
              })
              .catch(() => {
                document.querySelector(".is-cancel").click();
              });
          },
        },
      });
    },
    changeOrderStatus(status_id) {
      this.changeOrderStatusCall({
        order: this.order,
        id: this.$route.params.id,
        status_id,
      })
        .then(() => {
          this.getOrder();
          this.getServiceType();
        })
        .catch((error) => {
          this.order = {};
          this.$buefy.toast.open({
            duration: 5000,
            message: error.data.error_description,
            position: "is-bottom",
            type: "is-danger",
          });
        });
    },
    getCommuteItems() {
      this.getCommuteItemsCall({ id: this.$route.params.id })
        .then((resp) => (this.order_commute = resp))
        .catch((error) => {
          this.order_commute = {};
          this.$buefy.toast.open({
            duration: 5000,
            message: error.data.error_description,
            position: "is-bottom",
            type: "is-danger",
          });
        });
    },
    addCommuteItem() {
      const isEmpty = !Object.values(this.order_commute_item).every(
        (x) => x !== null && x !== ""
      );

      if (isEmpty) {
        alert("Uzupełnij wszytkie pola dojazdu");
      } else {
        let item = {
          ...this.order_commute_item,
          order_id: this.$route.params.id,
        };
        if (this.isOnline) {
          this.postCommuteItemsCall({ item })
            .then((resp) => {
              this.getOrder();
              this.order_commute.push(resp.data);
              this.$refs.observer.refs.order_commute.validate();
            })
            .catch((error) => {
              this.order_commute = {};
              this.$buefy.toast.open({
                duration: 5000,
                message: error.data.error_description,
                position: "is-bottom",
                type: "is-danger",
              });
            });
        }
        if (this.isOffline) {
          this.order_commute.push(item)
          this.order_offline.order_commute.push(item)
          this.storeOrderOffline()
        }          
        this.order_commute_item = {
          commuted_at: null,
          city_from: null,
          city_to: null,
          distance: null,
        };
      }
    },
    removeCommuteItem(id,index, item) {
      if (this.isOnline) {
        this.removeCommuteItemsCall({ id })
          .then(() => {
            this.getCommuteItems();
            this.getOrder();
          })
          .catch((error) => {
            this.order_commute = {};
            this.$buefy.toast.open({
              duration: 5000,
              message: error.data.error_description,
              position: "is-bottom",
              type: "is-danger",
            });
          });
      }
      if (this.isOffline) {
        if (id) {
          this.order_offline.order_commute_remove.push(item)
        } 
        this.order_commute.splice(index, 1)
        this.order_offline.order_commute.splice(index, 1)
        this.storeOrderOffline()
      }        
    },
    getWorkItems() {
      this.getWorkItemsCall({ id: this.$route.params.id })
        .then((resp) => (this.order_work = resp))
        .catch((error) => {
          this.order_work = {};
          this.$buefy.toast.open({
            duration: 5000,
            message: error.data.error_description,
            position: "is-bottom",
            type: "is-danger",
          });
        });
    },
    addWorkItem() {
      const isEmpty = !Object.values(this.order_work_item).every(
        (x) => x !== null && x !== ""
      );

      if (isEmpty) {
        alert("Uzupełnij wszytkie pola godzin pracy");
      } else {
        let item = {
          ...this.order_work_item,
          order_id: this.$route.params.id,
        };
        if (this.isOnline) {
          this.postWorkItemsCall({ item })
            .then((resp) => {
              this.order_work.push(resp.data);
              this.getOrder();
              this.$refs.observer.refs.order_work.validate();
            })
            .catch((error) => {
              this.order_work = {};
              this.$buefy.toast.open({
                duration: 5000,
                message: error.data.error_description,
                position: "is-bottom",
                type: "is-danger",
              });
            });
        }
        if (this.isOffline) {
          this.order_work.push(item)
          this.order_offline.order_work.push(item)
          this.storeOrderOffline()
        }        
        this.order_work_item = {
          date: null,
          time_from: null,
          time_to: null,
          workers: null,
        };
      }
    },
    removeWorkItem(id,index, item) {
      if (this.isOnline) {
        this.removeWorkItemsCall({ id })
          .then(() => {
            this.getWorkItems();
            this.getOrder();
          })
          .catch((error) => {
            this.order_work = {};
            this.$buefy.toast.open({
              duration: 5000,
              message: error.data.error_description,
              position: "is-bottom",
              type: "is-danger",
            });
          });
      }
      if (this.isOffline) {
        if (id) {
          this.order_offline.order_work_remove.push(item)
        } 
        this.order_work.splice(index, 1)
        this.order_offline.order_work.splice(index, 1)
        this.storeOrderOffline()
      }        
    },
    convertDate(date) {
      var yyyy = date.getFullYear().toString();
      var mm = (date.getMonth() + 1).toString();
      var dd = date.getDate().toString();

      var mmChars = mm.split("");
      var ddChars = dd.split("");

      return (
        yyyy +
        "-" +
        (mmChars[1] ? mm : "0" + mmChars[0]) +
        "-" +
        (ddChars[1] ? dd : "0" + ddChars[0])
      );
    },
    commuteDistanceSummary() {
      if (this.order_commute.length > 0) {
        return this.order_commute.reduce((a, b) => +a + +b.distance, 0) + " km";
      }
    },
    workMinutesSummary() {
      if (this.order_work.length > 0) {
        return (
          this.order_work.reduce((a, b) => +a + +b.workers_minutes, 0) +
          " minut"
        );
      }
    },
    draw() {
      var width = 800;
      var height = 200;
      var stage = new Konva.Stage({
        container: "drawContainer",
        width: width,
        height: height,
      });
      this.stage = stage;

      var layer = new Konva.Layer();
      stage.add(layer);
      var canvas = document.createElement("canvas");
      canvas.width = stage.width();
      canvas.height = stage.height();

      var image = new Konva.Image({
        image: canvas,
        x: 0,
        y: 0,
      });
      layer.add(image);
      stage.draw();

      var context = canvas.getContext("2d");
      context.strokeStyle = "#000";
      context.lineJoin = "round";
      context.lineWidth = 3;

      var isPaint = false;
      var lastPointerPosition;
      var mode = "brush";

      image.on("mousedown touchstart", () => {
        isPaint = true;
        this.signature = true;
        lastPointerPosition = stage.getPointerPosition();
      });

      // will it be better to listen move/end events on the window?

      stage.on("mouseup touchend", () => {
        isPaint = false;
      });

      // and core function - drawing
      stage.on("mousemove touchmove", function() {
        if (!isPaint) {
          return;
        }

        if (mode === "brush") {
          context.globalCompositeOperation = "source-over";
        }

        context.beginPath();

        var localPos = {
          x: lastPointerPosition.x - image.x(),
          y: lastPointerPosition.y - image.y(),
        };
        context.moveTo(localPos.x, localPos.y);
        var pos = stage.getPointerPosition();
        localPos = {
          x: pos.x - image.x(),
          y: pos.y - image.y(),
        };
        context.lineTo(localPos.x, localPos.y);
        context.closePath();
        context.stroke();

        lastPointerPosition = pos;
        layer.batchDraw();
      });

      document.getElementById("clearDraw").addEventListener("click", () => {
        //  var dataURL = stage.toDataURL();
        this.signature = null;
        context.clearRect(0, 0, canvas.width, canvas.height);
        layer.batchDraw();
      });
    },
    onSubmit() {
      if (this.isOnline) {
        let protocol = {
          ...this.order,
          ...this.protocol,
          status_id: 40,
          signature_stream: this.stage ? this.stage.toDataURL() : this.order.signature_stream,
        };
        this.postOrder({ order: protocol, id: this.$route.params.id })
          .then((resp) => {
            this.disabledSidebar(false);
            this.order = resp.data;
          })
          .catch((error) => {
            this.order = {};
            this.$buefy.toast.open({
              duration: 5000,
              message: error.data.error_description,
              position: "is-bottom",
              type: "is-danger",
            });
          });
      }
      if (this.isOffline) {
        this.order_offline.submit = true
        this.order_offline.order = this.order
        this.order_offline.order.status_id = 40
        this.order_offline.order.signature_stream = this.stage.toDataURL()
        this.order_offline.protocol = this.protocol
        document.querySelector('html').classList.add('overflow-y')
        this.storeOrderOffline()
        this.protocolToSend = true
      }
    },
    statusName: function() {
      let status = this.$store.state.auth.status.filter(
        (item) => item.id === this.order.status_id
      );
      if (status) {
        return status[0].name;
      }
    },
    statusColor: function() {
      let status = this.$store.state.auth.status.filter(
        (item) => item.id === this.order.status_id
      );
      if (status.length > 0) {
        return `color: ${status[0].colour}`;
      }
    },
    handleFileChange(file) {
      function getBase64(file) {
        return new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = () => resolve(reader.result);
          reader.onerror = (error) => reject(error);
        });
      }
      getBase64(file).then((data) => {
        let payload = {
          order_id: this.order.id,
          image_stream: data,
        };
        if (this.isOnline) {
          this.postOrderImage(payload)
            .then((resp) => {
              this.disabledSidebar(false);
              this.files.push(resp.data);
            })
            .catch((error) => {
              this.order = {};
              this.$buefy.toast.open({
                duration: 5000,
                message: error.data.error_description,
                position: "is-bottom",
                type: "is-danger",
              });
            });
        }
        if (this.isOffline) {
          this.order_offline.order_image.push(payload)
          this.files.push(payload)
          this.storeOrderOffline()
        }
      });
    },
    getOrderImage() {
      this.getOrderImageCall({ id: this.$route.params.id })
        .then(resp => {
            if(resp.length === 0) {
              this.file = null
              // this.$refs.observer.refs.files.validate()
            }
            this.files = resp
        })
        .catch((error) => {
          this.order_work = {};
          this.$buefy.toast.open({
            duration: 5000,
            message: error.data.error_description,
            position: "is-bottom",
            type: "is-danger",
          });
        });
    },
    removeOrderImage(id, index, item) {
      if (this.isOnline) {
        this.removeOrderImageCall({ id })
          .then(() => {
            this.getOrderImage();
          })
          .catch((error) => {
            this.order_work = {};
            this.$buefy.toast.open({
              duration: 5000,
              message: error.data.error_description,
              position: "is-bottom",
              type: "is-danger",
            });
          });
      }
      if (this.isOffline) {
        if (id) {
          this.order_offline.order_image_remove.push(item)
        } 
        this.files.splice(index, 1)
        this.order_offline.order_image.splice(index, 1)
        this.storeOrderOffline()
      }         
    },
    getOrderTechnical() {
      this.getOrderTechnicalCall({ id: this.$route.params.id })
        .then((resp) => (this.order_technical = resp))
        .catch((error) => {
          this.order_technical = {};
          this.$buefy.toast.open({
            duration: 5000,
            message: error ? error.data.error_description : null,
            position: "is-bottom",
            type: "is-danger",
          });
        });
    },
    addOrderTechnical() {
      const isEmpty = !Object.values(this.order_technical_item).every(
        (x) => x !== null && x !== ""
      );

      if (isEmpty) {
        alert("Uzupełnij wszytkie pola");
      } else {
        let item = {
          ...this.order_technical_item,
          order_id: this.$route.params.id,
        };
        if (this.isOnline) {
          this.postOrderTechnicalCall({ item })
            .then((resp) => {
              this.getOrderTechnical();
              this.order_technical.push(resp.data);
              this.$refs.observer.refs.order_technical.validate();
            })
            .catch((error) => {
              this.order_technical = [];
              this.$buefy.toast.open({
                duration: 5000,
                message: error ? error.data.error_description : null,
                position: "is-bottom",
                type: "is-danger",
              });
            });
        }
        if (this.isOffline) {
          this.order_technical.push(item)
          this.order_offline.order_technical.push(item)
          this.storeOrderOffline()
        }
        this.order_technical_item = {
          firstname: null,
          lastname: null,
        };
      }
    },
    editOrderTechnical(item, index) {
      if (this.isOnline) {
        this.editOrderTechnicalCall(item)
          .then(() => {
            this.getOrderTechnical();
          })
          .catch((error) => {
            this.order_commute = {};
            this.$buefy.toast.open({
              duration: 5000,
              message: error ? error.data.error_description : null,
              position: "is-bottom",
              type: "is-danger",
            });
          });
      }
      if (this.isOffline) {
        this.order_technical[index] = item
        this.storeOrderOffline()
      }
    },
    removeOrderTechnical(id, index, item) {
      if (this.isOnline) {
      this.removeOrderTechnicalCall({ id })
        .then(() => {
          this.getOrderTechnical();
        })
        .catch((error) => {
          this.order_work = {};
          this.$buefy.toast.open({
            duration: 5000,
            message: error ? error.data.error_description : null,
            position: "is-bottom",
            type: "is-danger",
          });
        });
      }
      if (this.isOffline) {
        if (id) {
          this.order_offline.order_technical_remove.push(item)
        } 
        this.order_technical.splice(index, 1)
        this.order_offline.order_technical.splice(index, 1)
        this.storeOrderOffline()
      }        
    },
    getOrderOffline() {
      return JSON.parse(localStorage.getItem('agro-order-offline'))
    },
    storeOrderOffline() {
      localStorage.setItem('agro-order-offline', JSON.stringify(this.order_offline))
    },
    onOfflineSubmit() {
      const promises = [];
      if (this.order_offline.order_technical.length > 0) {
        for (const technical of this.order_offline.order_technical) {
          promises.push(this.postOrderTechnicalCall({ item: technical}))
        }
      }
      if (this.order_offline.order_technical_remove.length > 0) {
        for (const technical of this.order_offline.order_technical_remove) {
          promises.push(this.removeOrderTechnicalCall({ id: technical.id }))
        }
      }
      if (this.order_offline.order_image.length > 0) {
        for (const image of this.order_offline.order_image) {
          promises.push(this.postOrderImage(image))
        }
      }
      if (this.order_offline.order_image_remove.length > 0) {
        for (const image of this.order_offline.order_image_remove) {
          promises.push(this.removeOrderImageCall({ id: image.id }))
        }
      }
      if (this.order_offline.order_work.length > 0) {
        for (const work of this.order_offline.order_work) {
          promises.push(this.postWorkItemsCall({ item: work }))
        }
      }
      if (this.order_offline.order_work_remove.length > 0) {
        for (const work of this.order_offline.order_work_remove) {
          promises.push(this.removeWorkItemsCall({ id: work.id }))
        }
      }
      if (this.order_offline.order_commute.length > 0) {
        for (const commute of this.order_offline.order_commute) {
          promises.push(this.postCommuteItemsCall({ item: commute }))
        }
      }
      if (this.order_offline.order_commute_remove.length > 0) {
        for (const commute of this.order_offline.order_commute_remove) {
          promises.push(this.removeCommuteItemsCall({ id: commute.id }))
        }
      }

      Promise.all(promises)
        .then(() => {
          this.onSubmit()
        })
        .then(() => {
          this.order_offline = {
            submit: false,
            order: {},
            protocol: {},
            order_technical: [],
            order_technical_remove: [],
            order_image: [],
            order_image_remove: [],
            order_work: [],
            order_work_remove: [],
            order_commute: [],
            order_commute_remove: [],
          }
          document.querySelector('html').classList.remove('overflow-y')
          localStorage.removeItem('agro-order-offline')
          this.protocolToSend = false
          this.protocolToSendAfterLogout = false
        }).then(() => {
          this.getOrder(true);
          this.getServiceType();
          this.getCommuteItems();
          this.getWorkItems();
          this.getOrderImage();
          this.getOrderTechnical();
          this.getWarrantyStatus();
        })

      // window.location.reload()
    }
  },
  destroyed() {},
};
</script>

<style lang="scss" scoped>
  .offlinemodal {
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
  }
  .offlinemodal-overlay {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
    background-color: #00000080;
    z-index: -1;
    // pointer-events: none;
  }
  .offlinemodal-content {
    margin-left: 1rem;
    margin-right: 1rem;
    max-width: 36rem;
    border-radius: 0.5rem;
    border-width: 1px;
    background-color: #fff;
    padding: 2rem;
    text-align: center;
  }
  .offlinemodal-content h3 {
    font-size: 18px;
    font-weight: 700;
    margin-bottom: 16px;
  }
.lds-dual-ring {
  display: inline-block;
  width: 80px;
  height: 80px;
}
.lds-dual-ring:after {
  content: " ";
  display: block;
  width: 64px;
  height: 64px;
  margin: 8px;
  border-radius: 50%;
  border: 6px solid #fff;
  border-color: #00711e transparent #00711e transparent;
  animation: lds-dual-ring 1.2s linear infinite;
}
@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

</style>

