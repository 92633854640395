<template>
  <div>
    <ValidationObserver v-slot="{ handleSubmit, errors }" ref="observer">
      <form class="login" @submit.prevent="handleSubmit(onSubmit)">
        <div class="ym-toptitle-box">
          <fixed-header :threshold="0" fixedClass="ym-toptitle-fixed">
            <div id="toptitle" class="level ym-toptitle">
              <div class="level-left">
                <a @click="cancelAdd" to="/service" class="button xbtn is-medium is-transparent">
                  <icon name="x"></icon>
                </a>
                <h2 class="title" v-if="isNew">Nowe zlecenie serwisowe</h2>
                <h2 class="title" v-else>Edytuj zlecenie serwisowe</h2>
              </div>
              <div v-if="isOnline" class='buttons level-right'>
                <button class="button is-medium is-primary">
                  <icon name="check"></icon><span>Zapisz</span>
                </button>
              </div>
            </div>
          </fixed-header>
        </div>
        <div
          :class="errors['customer'] && errors['customer'].length > 0 ? 'columns is-mobile is-variable is-3 is-multiline v-field v-error' : 'columns is-mobile is-variable is-3 is-multiline'">
          <div class="column ym-column is-3">
            <div class="ym-label">
              <label for="f_company_full">Klient</label>
              <span v-if="errors['customer'] && errors['customer'].length > 0"
                class="help is-danger">{{ errors['customer'][0] }}</span>
            </div>
          </div>
          <div class="column ym-column is-9">
            <ValidationProvider rules="required" name="customer" slim>
              <button v-if="Object.keys(customer).length === 0 && customer.constructor === Object" @click.prevent="chooseCustomer" class="button is-medium is-light likeinput" data-value="" data-label="" data-label-novalue="Wybierz klienta">
                  <i class="mdi mdi-chevron-right mdi-24px"></i>
              </button> 
              <button v-else @click.prevent="chooseCustomer" class="button is-medium is-light likeinput" :data-value="customer.name" :data-label="customer.name" data-label-novalue="Wybierz klienta">
                <input style="display: none" v-model="customer.id" type="text">
                <i class="mdi mdi-chevron-right mdi-24px"></i>
              </button> 
            </ValidationProvider>
          </div>
        </div>  
        <div
          v-if="Object.keys(customer).length !== 0 && customer.constructor === Object"
          :class="errors['customer'] && errors['customer'].length > 0 ? 'columns is-mobile is-variable is-3 is-multiline v-field v-error' : 'columns is-mobile is-variable is-3 is-multiline'">
          <div class="column ym-column is-3">
            <div class="ym-label">
             &nbsp;
            </div>
          </div>
          <div class="column ym-column is-9">
            <div class="field">
              <div class="control is-medium">
                <textarea class="textarea is-medium is-uppercase" v-model="customerInfo" type="text" placeholder="" readonly="readonly">

                </textarea>
              </div>
              <div v-if="customer.is_no_payment" class="is-info">Zlecenie tylko i wyłącznie za gotówke lub przedpłata.</div>
            </div>
          </div>
        </div>  
        <div
          :class="errors['operator'] && errors['operator'].length > 0 ? 'columns is-mobile is-variable is-3 is-multiline v-field v-error' : 'columns is-mobile is-variable is-3 is-multiline'">
          <div class="column ym-column is-3">
            <div class="ym-label">
              <label for="f_company_full">Operator</label>
              <span v-if="errors['operator'] && errors['operator'].length > 0"
                class="help is-danger">{{ errors['operator'][0] }}</span>
            </div>
          </div>
          <div class="column ym-column is-9">
            <ValidationProvider rules="required" name="operator" slim>
              <button v-if="Object.keys(operator).length === 0 && operator.constructor === Object" @click.prevent="chooseOperator" class="button is-medium is-light likeinput" data-value="" data-label="" data-label-novalue="Wybierz operatora">
                  <i class="mdi mdi-chevron-right mdi-24px"></i>
              </button> 
              <button v-else @click.prevent="chooseOperator" class="button is-medium is-light likeinput" :data-value="operator.lastname + ' ' + operator.firstname" :data-label="operator.lastname + ' ' + operator.firstname" data-label-novalue="Wybierz klienta">
                <input style="display: none" v-model="operator.id" type="text">
                <i class="mdi mdi-chevron-right mdi-24px"></i>
              </button>               
            </ValidationProvider>
          </div>
        </div>  
        <div
          :class="errors['device'] && errors['device'].length > 0 ? 'columns is-mobile is-variable is-3 is-multiline v-field v-error' : 'columns is-mobile is-variable is-3 is-multiline'">
          <div class="column ym-column is-3">
            <div class="ym-label">
              <label for="f_company_full">Maszyna</label>
              <span v-if="errors['device'] && errors['device'].length > 0"
                class="help is-danger">{{ errors['device'][0] }}</span>
            </div>
          </div>
          <div class="column ym-column is-9">
            <ValidationProvider rules="required" name="device" slim>
              <button  v-if="Object.keys(device).length === 0 && device.constructor === Object" @click.prevent="chooseDevice" class="button is-medium is-light likeinput" data-value="" data-label="" data-label-novalue="Wybierz maszyne">
                  <i class="mdi mdi-chevron-right mdi-24px"></i>
              </button> 
              <button v-else @click.prevent="chooseDevice" class="button is-medium is-light likeinput" :data-value="device.type + ': ' + device.name + '/' + device.serial" :data-label="device.type + ': ' + device.name + '/' + device.serial" data-label-novalue="Wybierz klienta">
                <input style="display: none" v-model="device.id" type="text">
                <i class="mdi mdi-chevron-right mdi-24px"></i>
              </button>                  
            </ValidationProvider>
          </div>
        </div>        
        <div
          v-if="Object.keys(device).length !== 0 && device.constructor === Object"
          :class="errors['device_address'] && errors['device_address'].length > 0 ? 'columns is-mobile is-variable is-3 is-multiline v-field v-error' : 'columns is-mobile is-variable is-3 is-multiline'">
          <div class="column ym-column is-3">
            <div class="ym-label">
              <label for="f_company_full">Miejsce postoju maszyny</label>
              <p>Podaj pełen adres postoju</p>
              <span v-if="errors['device_address'] && errors['device_address'].length > 0"
                class="help is-danger">{{ errors['device_address'][0] }}</span>
            </div>
          </div>
          <div class="column ym-column is-9">
            <div class="field">
              <div class="control has-icons-right is-clearfix">
                <ValidationProvider rules="required" name="device_address">
                  <input name="device_address" v-model="order.device_address" class="input is-medium is-uppercase" />
                  <span @click="clearDeviceAddress" class="icon is-right is-clickable"><i class="mdi mdi-close-circle mdi-24px"></i></span>
                </ValidationProvider>
              </div>
            </div>
          </div>
        </div>
        <div
          :class="errors['repared_at'] && errors['repared_at'].length > 0 ? 'columns is-mobile is-variable is-3 is-multiline v-field v-error' : 'columns is-mobile is-variable is-3 is-multiline'">
          <div class="column ym-column is-3">
            <div class="ym-label">
              <label for="f_company_full">Data naprawy</label>
              <span v-if="errors['repared_at'] && errors['repared_at'].length > 0"
                class="help is-danger">{{ errors['repared_at'][0] }}</span>
            </div>
          </div>
          <div class="column ym-column is-9">
            <div class="field">
              <div class="control is-medium">
                <ValidationProvider rules="required" name="repared_at">
                  <b-datepicker
                    :mobile-native="false"
                    name="repared_at"
                    v-model="repared_at"
                    placeholder="Wybierz"
                    icon="calendar-today"
                    :first-day-of-week="1"
                    trap-focus>
                  </b-datepicker>                  
                </ValidationProvider>
              </div>
            </div>
          </div>             
        </div> 
        <div 
        :class="errors['is_np'] && errors['is_np'].length > 0 ? 'columns is-mobile is-variable is-3 is-multiline v-field v-error' : 'columns is-mobile is-variable is-3 is-multiline'">
          <div class="column ym-column totop is-3">
              <div class="ym-label">
                  <label for="f_dm_company">NP</label>
                  <span v-if="errors['is_np'] && errors['is_np'].length > 0"
                class="help is-danger">{{ errors['is_np'][0] }}</span>                  
              </div>
          </div>
          <div class="column ym-column is-9">
            <div class="ym-rowbor">
              <div class="level ym-rowbor-item">
                <div class="ym-radios w150px">
                    <label>
                      <ValidationProvider name="is_np">
                        <input type="radio" name="is_np" v-model="order.is_np" :value="true">
                        <span>Tak</span>
                      </ValidationProvider>
                    </label>
                    <label>
                      <ValidationProvider name="is_np">
                        <input type="radio" name="is_np" v-model="order.is_np" :value="false">
                        <span>Nie</span>
                      </ValidationProvider>
                    </label>
                </div>
              </div>
            </div>
          </div>
        </div>        
        <div 
        :class="errors['is_warranty'] && errors['is_warranty'].length > 0 ? 'columns is-mobile is-variable is-3 is-multiline v-field v-error' : 'columns is-mobile is-variable is-3 is-multiline'">
          <div class="column ym-column totop is-3">
              <div class="ym-label">
                  <label for="f_dm_company">Gwarancja</label>
                  <span v-if="errors['is_warranty'] && errors['is_warranty'].length > 0"
                class="help is-danger">{{ errors['is_warranty'][0] }}</span>                  
              </div>
          </div>
          <div class="column ym-column is-9">
            <div class="ym-rowbor">
              <div class="level ym-rowbor-item">
                <div class="ym-radios w150px">
                    <label>
                      <ValidationProvider rules="required" name="is_warranty">
                        <input type="radio" name="is_warranty" v-model="order.is_warranty" :value="true" :disabled="isWarrantyDisabled">
                        <span>Tak</span>
                      </ValidationProvider>
                    </label>
                    <label>
                      <ValidationProvider rules="required" name="is_warranty">
                        <input type="radio" name="is_warranty" v-model="order.is_warranty" :value="false" :disabled="isWarrantyDisabled">
                        <span>Nie</span>
                      </ValidationProvider>
                    </label>
                </div>
              </div>
            </div>
          </div>
        </div> 
        <div
          v-if="order.is_warranty"
          class="columns is-mobile is-variable is-3 is-multiline"
        >
          <div class="column ym-column totop is-3">
            <div class="ym-label">
              <label for="f_dm_company">Status gwarancji</label>
            </div>
          </div>
          <div class="column ym-column is-9">
            <div class="field">
              <div class="control is-medium">
                <ValidationProvider name="warranty_status_id">
                  <select name="warranty_status_id" v-model="order.warranty_status_id" class="select is-medium is-uppercase">
                    <option :value="0">Brak</option>
                    <option v-for="status in warrantyStatus.filter(item => item.is_active)" :key="status.id" :value="status.id">{{ status.name }}</option>
                  </select>
                </ValidationProvider>
              </div>
            </div>
          </div>
        </div>          
        <div
          :class="errors['device_hours'] && errors['device_hours'].length > 0 ? 'columns is-mobile is-variable is-3 is-multiline v-field v-error' : 'columns is-mobile is-variable is-3 is-multiline'">
          <div class="column ym-column is-3">
            <div class="ym-label">
              <label for="f_company_full">Liczba motogodzin</label>
              <span v-if="errors['device_hours'] && errors['device_hours'].length > 0"
                class="help is-danger">{{ errors['device_hours'][0] }}</span>
            </div>
          </div>
          <div class="column ym-column is-9">
            <div class="field">
              <div class="control is-medium">
                <ValidationProvider rules="required" name="device_hours">
                  <input name="device_hours" type="number" v-model="order.device_hours" class="input is-medium is-uppercase" />
                </ValidationProvider>
              </div>
            </div>
          </div>             
        </div>             
        <div
          :class="errors['service_type_id'] && errors['service_type_id'].length > 0 ? 'columns is-mobile is-variable is-3 is-multiline v-field v-error' : 'columns is-mobile is-variable is-3 is-multiline'">
          <div class="column ym-column is-3">
            <div class="ym-label">
              <label for="f_company_full">Kategoria prac</label>
              <span v-if="errors['service_type_id'] && errors['service_type_id'].length > 0"
                class="help is-danger">{{ errors['service_type_id'][0] }}</span>
            </div>
          </div>
          <div class="column ym-column is-9">
            <div class="field">
              <div class="control is-medium">
                <ValidationProvider rules="required" name="service_type_id">
                  <select name="service_type_id" v-model="order.service_type_id" class="select is-medium is-uppercase">
                    <option v-for="type in serviceType.filter(item => item.is_active)" :key="type.id" :value="type.id">{{ type.name }}</option>
                  </select>
                </ValidationProvider>
              </div>
            </div>
          </div>             
        </div>             
        <div
          :class="errors['device_service_work'] && errors['device_service_work'].length > 0 ? 'columns is-mobile is-variable is-3 is-multiline v-field v-error' : 'columns is-mobile is-variable is-3 is-multiline'">
          <div class="column ym-column is-3">
            <div class="ym-label">
              <label for="f_company_full">Czynności do wykonania</label>
              <span v-if="errors['device_service_work'] && errors['device_service_work'].length > 0"
                class="help is-danger">{{ errors['device_service_work'][0] }}</span>
            </div>
          </div>
          <div class="column ym-column is-9">
            <div class="field">
              <div class="control is-medium">
                <ValidationProvider rules="required" name="device_service_work">
                  <textarea name="device_service_work" v-model="order.device_service_work" class="input is-medium is-uppercase" />
                </ValidationProvider>
              </div>
            </div>
          </div>             
        </div>             
        <div
          :class="errors['operator_notes'] && errors['operator_notes'].length > 0 ? 'columns is-mobile is-variable is-3 is-multiline v-field v-error' : 'columns is-mobile is-variable is-3 is-multiline'">
          <div class="column ym-column is-3">
            <div class="ym-label">
              <label for="f_company_full">Uwagi operatora</label>
              <span v-if="errors['operator_notes'] && errors['operator_notes'].length > 0"
                class="help is-danger">{{ errors['operator_notes'][0] }}</span>
            </div>
          </div>
          <div class="column ym-column is-9">
            <div class="field">
              <div class="control is-medium">
                <ValidationProvider rules="" name="operator_notes">
                  <textarea name="operator_notes" v-model="order.operator_notes" class="input is-medium is-uppercase" />
                </ValidationProvider>
              </div>
            </div>
          </div>             
        </div>
        <div v-if="order.status_id === 30 || order.status_id === 40">
          <hr>
          <div class="ym-toptitle-box">
              <div id="toptitle" class="level ym-toptitle">
                <div class="level-left">
                  <h2 class="title">Protokół serwisowy</h2>
                </div>
              </div>
          </div>
          <div
            :class="errors['service_fault'] && errors['service_fault'].length > 0 ? 'columns is-mobile is-variable is-3 is-multiline v-field v-error' : 'columns is-mobile is-variable is-3 is-multiline'">
            <div class="column ym-column is-3">
              <div class="ym-label">
                <label for="f_company_full">Opis usterki</label>
                <span v-if="errors['service_fault'] && errors['service_fault'].length > 0"
                  class="help is-danger">{{ errors['service_fault'][0] }}</span>
              </div>
            </div>
            <div class="column ym-column is-9">
              <div class="field">
                <div class="control is-medium">
                  <ValidationProvider rules="required" name="service_fault">
                    <textarea name="service_fault" v-model="order.service_fault" class="input is-medium is-uppercase" />
                  </ValidationProvider>
                </div>
              </div>
            </div>             
          </div> 
          <div
            :class="errors['service_description'] && errors['service_description'].length > 0 ? 'columns is-mobile is-variable is-3 is-multiline v-field v-error' : 'columns is-mobile is-variable is-3 is-multiline'">
            <div class="column ym-column is-3">
              <div class="ym-label">
                <label for="f_company_full">Opis wykonywanych czynności</label>
                <span v-if="errors['service_description'] && errors['service_description'].length > 0"
                  class="help is-danger">{{ errors['service_description'][0] }}</span>
              </div>
            </div>
            <div class="column ym-column is-9">
              <div class="field">
                <div class="control is-medium">
                  <ValidationProvider rules="required" name="service_description">
                    <textarea name="service_description" v-model="order.service_description" class="input is-medium is-uppercase" />
                  </ValidationProvider>
                </div>
              </div>
            </div>             
          </div>  
          <div
            :class="errors['technical_notes'] && errors['technical_notes'].length > 0 ? 'columns is-mobile is-variable is-3 is-multiline v-field v-error' : 'columns is-mobile is-variable is-3 is-multiline'">
            <div class="column ym-column is-3">
              <div class="ym-label">
                <label for="f_company_full">Uwagi serwisanta</label>
                <span v-if="errors['technical_notes'] && errors['technical_notes'].length > 0"
                  class="help is-danger">{{ errors['technical_notes'][0] }}</span>
              </div>
            </div>
            <div class="column ym-column is-9">
              <div class="field">
                <div class="control is-medium">
                  <ValidationProvider name="technical_notes">
                    <textarea name="technical_notes" v-model="order.technical_notes" class="input is-medium is-uppercase" />
                  </ValidationProvider>
                </div>
              </div>
            </div>             
          </div>                   
          <div
            :class="errors['device_area'] && errors['device_area'].length > 0 ? 'columns is-mobile is-variable is-3 is-multiline v-field v-error' : 'columns is-mobile is-variable is-3 is-multiline'">
            <div class="column ym-column is-3">
              <div class="ym-label">
                <label for="f_company_full">Liczba ha</label>
                <span v-if="errors['device_area'] && errors['device_area'].length > 0"
                  class="help is-danger">{{ errors['device_area'][0] }}</span>
              </div>
            </div>
            <div class="column ym-column is-9">
              <div class="field">
                <div class="control is-medium">
                  <ValidationProvider rules="required" name="device_area">
                    <input name="device_area" type="number" v-model="order.device_area" class="input is-medium is-uppercase" />
                  </ValidationProvider>
                </div>
              </div>
            </div>             
          </div>  
          <div
            :class="errors['device_bales'] && errors['device_bales'].length > 0 ? 'columns is-mobile is-variable is-3 is-multiline v-field v-error' : 'columns is-mobile is-variable is-3 is-multiline'">
            <div class="column ym-column is-3">
              <div class="ym-label">
                <label for="f_company_full">Liczba bali</label>
                <span v-if="errors['device_bales'] && errors['device_bales'].length > 0"
                  class="help is-danger">{{ errors['device_bales'][0] }}</span>
              </div>
            </div>
            <div class="column ym-column is-9">
              <div class="field">
                <div class="control is-medium">
                  <ValidationProvider rules="required" name="device_bales">
                    <input name="device_bales" type="number" v-model="order.device_bales" class="input is-medium is-uppercase" />
                  </ValidationProvider>
                </div>
              </div>
            </div>             
          </div>
          <div 
          :class="errors['is_paid'] && errors['is_paid'].length > 0 ? 'columns is-mobile is-variable is-3 is-multiline v-field v-error' : 'columns is-mobile is-variable is-3 is-multiline'">
            <div class="column ym-column totop is-3">
                <div class="ym-label">
                    <label for="f_dm_company">Płatne</label>
                    <span v-if="errors['is_paid'] && errors['is_paid'].length > 0"
                  class="help is-danger">{{ errors['is_paid'][0] }}</span>                  
                </div>
            </div>
            <div class="column ym-column is-9">
              <div class="ym-rowbor">
                <div class="level ym-rowbor-item">
                  <div class="ym-radios w150px">
                      <label>
                        <ValidationProvider rules="required" name="is_paid">
                          <input type="radio" name="is_paid" v-model="order.is_paid" :value="true">
                          <span>Tak</span>
                        </ValidationProvider>
                      </label>
                      <label>
                        <ValidationProvider rules="required" name="is_paid">
                          <input type="radio" name="is_paid" v-model="order.is_paid" :value="false">
                          <span>Nie</span>
                        </ValidationProvider>
                      </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div 
          :class="errors['is_trade'] && errors['is_trade'].length > 0 ? 'columns is-mobile is-variable is-3 is-multiline v-field v-error' : 'columns is-mobile is-variable is-3 is-multiline'">
            <div class="column ym-column totop is-3">
                <div class="ym-label">
                    <label for="f_dm_company">Handel</label>
                    <span v-if="errors['is_trade'] && errors['is_trade'].length > 0"
                  class="help is-danger">{{ errors['is_trade'][0] }}</span>                  
                </div>
            </div>
            <div class="column ym-column is-9">
              <div class="ym-rowbor">
                <div class="level ym-rowbor-item">
                  <div class="ym-radios w150px">
                      <label>
                        <ValidationProvider rules="required" name="is_trade">
                          <input type="radio" name="is_trade" v-model="order.is_trade" :value="true">
                          <span>Tak</span>
                        </ValidationProvider>
                      </label>
                      <label>
                        <ValidationProvider rules="required" name="is_trade">
                          <input type="radio" name="is_trade" v-model="order.is_trade" :value="false">
                          <span>Nie</span>
                        </ValidationProvider>
                      </label>
                  </div>
                </div>
              </div>
            </div>
          </div> 
          <div 
          :class="errors['is_service'] && errors['is_service'].length > 0 ? 'columns is-mobile is-variable is-3 is-multiline v-field v-error' : 'columns is-mobile is-variable is-3 is-multiline'">
            <div class="column ym-column totop is-3">
                <div class="ym-label">
                    <label for="f_dm_company">Usługi</label>
                    <span v-if="errors['is_service'] && errors['is_service'].length > 0"
                  class="help is-danger">{{ errors['is_service'][0] }}</span>                  
                </div>
            </div>
            <div class="column ym-column is-9">
              <div class="ym-rowbor">
                <div class="level ym-rowbor-item">
                  <div class="ym-radios w150px">
                      <label>
                        <ValidationProvider rules="required" name="is_service">
                          <input type="radio" name="is_service" v-model="order.is_service" :value="true">
                          <span>Tak</span>
                        </ValidationProvider>
                      </label>
                      <label>
                        <ValidationProvider rules="required" name="is_service">
                          <input type="radio" name="is_service" v-model="order.is_service" :value="false">
                          <span>Nie</span>
                        </ValidationProvider>
                      </label>
                  </div>
                </div>
              </div>
            </div>
          </div> 
          <div 
          :class="errors['order_technical'] && errors['order_technical'].length > 0 ? 'columns is-mobile is-variable is-3 is-multiline v-field v-error' : 'columns is-mobile is-variable is-3 is-multiline'">
            <div class="column ym-column totop is-3">
                <div class="ym-label">
                    <label for="f_dm_company">Serwisanci asystujący</label>
                    <span v-if="errors['order_technical'] && errors['order_technical'].length > 0"
                  class="help is-danger">{{ errors['order_technical'][0] }}</span>                  
                </div>
            </div>
            <div class="column is-9">
              <div v-if="order_technical.length > 0" style="margin-bottom: 16px;">
                <b-field v-for="technical in order_technical" :key="technical.id" grouped>
                  <b-field expanded><input class="input  is-uppercase" type="text" v-model="technical.firstname"></b-field>
                  <b-field expanded><input class="input  is-uppercase" type="text" v-model="technical.lastname"><!-- <small v-if="commute.time_from !== '00:00'">({{ commute.time_from }})</small> --></b-field>
                  <div class="ym-tableItemWrap">
                    <div class="button" @click="removeOrderTechnical(technical.id)"><icon name="x"></icon></div>
                    <div class="button" @click="editOrderTechnical(technical)"><icon name="check"></icon></div>
                  </div>
                </b-field>
              </div>
           
              <b-field grouped>
                <b-field expanded>
                  <input name="order_technical_firstname" type="text" v-model="order_technical_item.firstname" placeholder="Imię" class="input is-medium is-uppercase" />
                </b-field>
                <b-field expanded>
                  <input name="order_technical_lastname" type="text" v-model="order_technical_item.lastname" placeholder="Nazwisko" class="input is-medium is-uppercase" />
                </b-field>
                <b-field expanded><div type="button" @click="addOrderTechnical" class="button is-medium is-dark" v-if="online">dodaj</div></b-field>
              </b-field>    
            </div>        
            <ValidationProvider name="order_technical">
              <input type="hidden" name="order_technical" v-model="order_technical">
            </ValidationProvider> 
          </div>          
          <div 
          :class="errors['order_commute'] && errors['order_commute'].length > 0 ? 'columns is-mobile is-variable is-3 is-multiline v-field v-error' : 'columns is-mobile is-variable is-3 is-multiline'">
            <div class="column ym-column totop is-3">
                <div class="ym-label">
                    <label for="f_dm_company">Dojazd</label>
                    <span v-if="errors['order_commute'] && errors['order_commute'].length > 0"
                  class="help is-danger">{{ errors['order_commute'][0] }}</span>                  
                </div>
            </div>
            <div class="column is-12">
              <!-- <div style="display: flex; align-items: center; justify-content: flex-end;">
                  <div class="ym-label">
                    <label for="f_dm_company">{{ commuteDistanceSummary() }}</label>
                  </div>
              </div> -->
              <div v-if="order_commute.length > 0" style="margin-bottom: 16px;">
                <b-field v-for="commute in order_commute" :key="commute.id" grouped>
                  <b-field expanded><input class="input  is-uppercase" type="date" v-model="commute.commuted_at"></b-field>
                  <b-field expanded><input class="input  is-uppercase" type="text" v-model="commute.city_from"><!-- <small v-if="commute.time_from !== '00:00'">({{ commute.time_from }})</small> --></b-field>
                  <b-field expanded><input class="input  is-uppercase" type="text" v-model="commute.city_to"><!-- <small v-if="commute.time_to !== '00:00'">({{ commute.time_to }})</small> --></b-field>
                  <b-field expanded><input class="input  is-uppercase" type="text" v-model="commute.distance"></b-field>
                  <div class="ym-tableItemWrap">
                    <div class="button" @click="removeCommuteItem(commute.id)"><icon name="x"></icon></div>
                    <div class="button" @click="editCommuteItem(commute)"><icon name="check"></icon></div>
                  </div>
                </b-field>
              </div>
              <b-field grouped>
                   <b-field expanded>
                    <b-datepicker
                      :mobile-native="false"
                      name="order_commute_date"
                      v-model="order_commute_item.commuted_at"
                      placeholder="Data"
                      icon="calendar-today"
                      :first-day-of-week="1"
                      position="is-top-right"
                      trap-focus>
                    </b-datepicker>  
                  </b-field>
                   <b-field expanded>
                    <input name="order_commute_from" type="text" v-model="order_commute_item.city_from" placeholder="Dojazd z" class="input is-medium is-uppercase" />
                  </b-field>
                   <!-- <b-field expanded>
                    <b-timepicker size="is-medium" v-model="order_commute_item.time_from" placeholder="Od"></b-timepicker>
                  </b-field> -->
                   <b-field expanded>
                    <input name="order_commute_to" type="text" v-model="order_commute_item.city_to" placeholder="Dojazd do" class="input is-medium is-uppercase" />
                  </b-field>
                   <!-- <b-field expanded>
                    <b-timepicker size="is-medium" v-model="order_commute_item.time_to" placeholder="Do"></b-timepicker>
                  </b-field> -->
                   <b-field expanded>
                    <input name="order_commute_distance" type="number" v-model="order_commute_item.distance" placeholder=" km" class="input is-medium is-uppercase" />
                  </b-field>
                   <b-field expanded><div type="button" @click="addCommuteItem" class="button is-medium is-dark" v-if="online">dodaj</div></b-field>
                </b-field>
            </div>
            <ValidationProvider rules="required" name="order_commute">
              <input type="hidden" name="order_commute" v-model="order_commute">
            </ValidationProvider>            
          </div>  
          <div 
          :class="errors['order_work'] && errors['order_work'].length > 0 ? 'columns is-mobile is-variable is-3 is-multiline v-field v-error' : 'columns is-mobile is-variable is-3 is-multiline'">
            <div class="column ym-column totop is-3">
                <div class="ym-label">
                    <label for="f_dm_company">Godziny pracy</label>
                    <span v-if="errors['order_work'] && errors['order_work'].length > 0"
                  class="help is-danger">{{ errors['order_work'][0] }}</span>                  
                </div>
            </div>
            <div class="column is-12">
              <!-- <div style="display: flex; align-items: center; justify-content: flex-end;">
                  <div class="ym-label">
                    <label for="f_dm_company" v-if="order && order.work_workers_time !== `00:00`">{{ order.work_workers_time }}</label>
                  </div>
              </div> -->
              <div v-if="order_work.length > 0" style="margin-bottom: 16px;">
                <b-field v-for="work in order_work" :key="work.id" grouped>
                  <b-field expanded><input class="input  is-uppercase" type="date" v-model="work.worked_at"></b-field>
                  <b-field expanded><input class="input  is-uppercase" type="text" v-model="work.time_from"></b-field>
                  <b-field expanded><input class="input  is-uppercase" type="text" v-model="work.time_to"></b-field>
                  <b-field expanded><input class="input  is-uppercase" type="text" v-model="work.workers"></b-field>
                  <div class="ym-tableItemWrap">
                    <div class="button" @click="removeWorkItem(work.id)"><icon name="x"></icon></div>
                    <div class="button" @click="editWorkItem(work)"><icon name="check"></icon></div>
                  </div>                  
                </b-field>
              </div>
              <b-field grouped>
                  <b-field expanded>
                    <b-datepicker
                      :mobile-native="false"
                      name="order_work_date"
                      v-model="order_work_item.date"
                      placeholder="Data"
                      icon="calendar-today"
                      :first-day-of-week="1"
                      position="is-top-right"
                      trap-focus>
                    </b-datepicker>  
                  </b-field>
                  <b-field expanded> 
                    <b-timepicker size="is-medium" v-model="order_work_item.time_from" placeholder="Od"></b-timepicker>
                  </b-field>
                  <b-field expanded>
                    <b-timepicker size="is-medium" v-model="order_work_item.time_to" placeholder="Do"></b-timepicker>
                  </b-field>
                  <b-field expanded>
                    <input name="order_work_distance" type="text" v-model="order_work_item.workers" placeholder="Liczba serwisantów" class="input is-medium is-uppercase" />
                  </b-field>
                  <b-field expanded><div type="button" @click="addWorkItem" class="button is-medium is-dark" v-if="online">dodaj</div></b-field>
              </b-field>

            </div>
            <ValidationProvider rules="required" name="order_work">
              <input type="hidden" name="order_work" v-model="order_work">
            </ValidationProvider>            
          </div>  
          <div 
          :class="errors['files'] && errors['files'].length > 0 ? 'columns is-mobile is-variable is-3 is-multiline v-field v-error' : 'columns is-mobile is-variable is-3 is-multiline'">
            <div class="column ym-column totop is-12">
              <div class="ym-label">
                  <label for="f_dm_company">Zdjęcie</label>
                  <span v-if="errors['files'] && errors['files'].length > 0"
                  class="help is-danger">{{ errors['files'][0] }}</span>  
              </div>
            </div>            
            <div v-show="online" class="column is-12" style="margin-bottom: 24px">
              <ValidationProvider :rules="order.is_warranty && files.length === 0 ? 'required' : ''" name="files">              
                <b-upload @input="handleFileChange(file)" accept="image/*" v-model="file" v-if="files.length < 3">
                    <section class="section">
                        <div class="content has-text-centered">
                            <p>
                                <b-icon
                                    icon="upload"
                                    size="is-large">
                                </b-icon>
                            </p>
                            <p>Dodaj zdjęcie</p>
                        </div>
                    </section>
                </b-upload>   
              </ValidationProvider>         
            </div>
            <div class="column is-12">
              <div class="columns is-multiline">
                  <span v-for="(file, index) in files"
                      :key="index"
                      class="column is-4" >
                      <img :src="file.url" />
                      <div class="buttons">
                          <b-button @click="removeOrderImage(file.id)" type="is-dark" expanded>
                              <span>Usuń zdjęcie</span>
                          </b-button>
                      </div>
                  </span>
              </div>
            </div>
          </div>                                                  
        </div>                       
      </form>
      <!-- <div class="confirmButtons" v-if="!online">
        <div class="button is-medium is-fullwidth is-error">Brak połączenia z internetem</div>
      </div>       -->
    </ValidationObserver>
  </div>
</template>

<script>
  import FixedHeader from 'vue-fixed-header'
  import {mapActions, mapGetters} from 'vuex'
  import YMmodal from '@/components/Modal.vue';

  import {
    ValidationObserver,
    ValidationProvider
  } from 'vee-validate';

    export default {
    name: 'ServiceForm',
    props: {
      id: {
        type: Number,
        default: 0
      }
    },
    components: {
      FixedHeader,
      ValidationObserver,
      ValidationProvider
    },
    data: function () {
      return {
        warrantyStatus: [],
        serviceType: [],
        order_commute: [],
        order_commute_item: {
          commuted_at: new Date(),
          city_from: null,
          city_to: null,
          distance: null
        },  
        order_work: [],
        order_work_item: {
          date: new Date(),
          time_from: null,
          time_to: null,
          workers: 1
        },  
        file: null,
        files: [],
        order_technical: [],
        order_technical_item: {
          firstname: null,
          lastname: null,
        },
        online: navigator.onLine,
        isWarrantyDisabled: false,
      }
    },
    computed: {
      ...mapGetters({
        isDisabledSidebar: 'disabledSidebar',
        order: 'orders/order',
        customer: 'orders/customer',
        device: 'orders/device',
        technician: 'orders/technician',
        operator: 'orders/operator',
        user: 'auth/user'
      }),
      isNew() {
        return this.id === 0 ? true : false
      },
      customerInfo() {
        const tpl = 
`${this.customer.name}
${this.customer.address}
${this.customer.postcode ? this.customer.postcode : ''} ${this.customer.city ? this.customer.city : ''}
NIP ${this.customer.nip}
Telefon ${this.customer.phone}`
        return tpl
      },
      repared_at: {
        get() {
          let value = this.$store.state.orders.order.repared_at ? new Date(this.$store.state.orders.order.repared_at) : new Date()
          const editedOrder = { ...this.order, repared_at: value }
          this.$store.commit('orders/setOrder', editedOrder)          
          return value          
        },
        set(value) {
          const editedOrder = { ...this.order, repared_at: value }
          this.$store.commit('orders/setOrder', editedOrder)
        }
      }
    },
    methods: {
      ...mapActions({
        getOrder: 'orders/getOrder',
        postOrder: 'orders/postOrder',
        disabledSidebar: 'setDisabledSidebar',
        postCommuteItemsCall: 'orders/postCommuteItems',
        getCommuteItemsCall: 'orders/getCommuteItems',
        editCommuteItemsCall: 'orders/editCommuteItems',
        removeCommuteItemsCall: 'orders/removeCommuteItems',
        postWorkItemsCall: 'orders/postWorkItems',
        getWorkItemsCall: 'orders/getWorkItems',
        editWorkItemsCall: 'orders/editWorkItems',
        removeWorkItemsCall: 'orders/removeWorkItems',
        getOrderImageCall: 'orders/getOrderImage',
        postOrderImage: 'orders/postOrderImage',
        removeOrderImageCall: 'orders/removeOrderImage',  
        getOrderTechnicalCall: 'orders/getOrderTechnical',
        postOrderTechnicalCall: 'orders/postOrderTechnical',
        editOrderTechnicalCall: 'orders/editOrderTechnical',
        removeOrderTechnicalCall: 'orders/removeOrderTechnical', 
        getServiceTypeCall: "orders/serviceType",
        getWarrantyStatusCall: "orders/warrantyStatus",
      }),
      getWarrantyStatus() {
        this.getWarrantyStatusCall()
          .then((resp) => (this.warrantyStatus = resp))
          .catch((error) => {
            this.serviceType = {};
            this.$buefy.toast.open({
              duration: 5000,
              message: error.data.error_description,
              position: "is-bottom",
              type: "is-danger",
            });
          });
      },        
      onchange() {
        this.online = navigator.onLine;
        this.$emit(this.online ? 'online' : 'offline');
      },       
      cancelAdd() {
        if(this.isDisabledSidebar) {
          this.$buefy.modal.open({
              parent: this,
              component: YMmodal,
              hasModalCard: true,
              props: {
                title: 'Czy na pewno chcesz przerwać',
                content: '',
                cancel: 'Nie',
                ymHasIcon: true,
                ymIcon: 'x',
                submit: `<span class="mal50i">Przerwij</span>`
              }
          })
        }
      },    
      onSubmit() {
        const orderPost = {
          ...this.order,
          technical_id: this.user.id,
          operator_id: this.operator.id,
          customer_id: this.customer.id,
          device_serial: this.device.serial,
          device_type: this.device.type,
          device_name: this.device.name,
        }

        this.postOrder({order: orderPost, id: this.$route.params.id })
          .then(resp => {
            this.disabledSidebar(false)
            this.$router.push({name: 'ServiceDetails', params: { id: resp.data.id }})
          })
          .catch((error) => {
            this.order = {}
            this.$buefy.toast.open({
              duration: 5000,
              message: error.data.error_description,
              position: 'is-bottom',
              type: 'is-danger'
            })                            
          }) 
      },
      chooseCustomer() {
        this.$store.dispatch('orders/setSelectACustomer', true)
          .then(() => {
            this.$router.push({ name: 'Customer' })
          })
      },      
      chooseTechnician() {
        this.$store.dispatch('orders/setSelectATechnician', true)
          .then(() => {
            this.$router.push({ name: 'ServiceTechnician' })
          })
      },      
      chooseOperator() {
        this.$store.dispatch('orders/setSelectAOperator', true)
          .then(() => {
            this.$router.push({ name: 'Operators' })
          })
      },      
      chooseDevice() {
        this.$store.dispatch('orders/setSelectADevice', true)
          .then(() => {
            this.$router.push({ name: 'Machinery' })
          })
      },  
      clearDeviceAddress() {
        this.order.device_address = ''
      },
      getCommuteItems() {
        this.getCommuteItemsCall({ id: this.$route.params.id })
          .then(resp => this.order_commute = resp)
          .catch((error) => {
            this.order_commute = {}
            this.$buefy.toast.open({
              duration: 5000,
              message: error ? error.data.error_description : null,
              position: 'is-bottom',
              type: 'is-danger'
            })                            
          })
      },      
      addCommuteItem() {
        const isEmpty = !Object.values(this.order_commute_item).every(x => (x !== null && x !== ''));

        if(isEmpty) {
          alert('Uzupełnij wszytkie pola dojazdu')
        } else {
          let item = {
            ...this.order_commute_item,
            order_id: this.$route.params.id
          }
          this.postCommuteItemsCall({ item })
            .then((resp) => {
              this.getCommuteItems()
              this.order_commute.push(resp.data)
              this.$refs.observer.refs.order_commute.validate()
            })
            .catch(() => {
              this.order_commute = {}
            })
          this.order_commute_item = {
            commuted_at: new Date(),
            city_from: null,
            city_to: null,
            distance: null
          }  
                 
        }
      },
      editCommuteItem(item) {
        this.editCommuteItemsCall( item )
          .then(() => {
            this.getCommuteItems()
          })
          .catch((error) => {
            this.order_commute = {}
            this.$buefy.toast.open({
              duration: 5000,
              message: error ? error.data.error_description : null,
              position: 'is-bottom',
              type: 'is-danger'
            })                            
          })
      },
      removeCommuteItem(id) {
        this.removeCommuteItemsCall({ id })
          .then(() => {
            this.getCommuteItems()
          })
          .catch((error) => {
            this.order_commute = {}
            this.$buefy.toast.open({
              duration: 5000,
              message: error ? error.data.error_description : null,
              position: 'is-bottom',
              type: 'is-danger'
            })                            
          })
      },
      getWorkItems() {
        this.getWorkItemsCall({ id: this.$route.params.id })
          .then(resp => this.order_work = resp)
          .catch((error) => {
            this.order_work = {}
            this.$buefy.toast.open({
              duration: 5000,
              message: error ? error.data.error_description : null,
              position: 'is-bottom',
              type: 'is-danger'
            })                            
          })
      },         
      addWorkItem() {
        const isEmpty = !Object.values(this.order_work_item).every(x => (x !== null && x !== ''));

        if(isEmpty) {
          alert('Uzupełnij wszytkie pola godzin pracy')
        } else {
          let item = {
            ...this.order_work_item,
            order_id: this.$route.params.id
          }
          this.postWorkItemsCall({ item })
            .then((resp) => {
              this.getWorkItems()
              this.order_work.push(resp.data)
              this.$refs.observer.refs.order_work.validate()

            })
            .catch((error) => {
              this.order_work = {}
              this.$buefy.toast.open({
                duration: 5000,
                message: error ? error.data.error_description : null,
                position: 'is-bottom',
                type: 'is-danger'
              })                            
            })
          this.order_work_item = {
            date: new Date(),
            time_from: null,
            time_to: null,
            workers: null
          }  
        }
      },
      editWorkItem(item) {
        this.editWorkItemsCall( item )
          .then(() => {
            this.getWorkItems()
          })
          .catch((error) => {
            this.order_commute = {}
            this.$buefy.toast.open({
              duration: 5000,
              message: error ? error.data.error_description : null,
              position: 'is-bottom',
              type: 'is-danger'
            })                            
          })
      },      
      removeWorkItem(id) {
        this.removeWorkItemsCall({ id })
          .then(() => {
            this.getWorkItems()
          })
          .catch((error) => {
            this.order_work = {}
            this.$buefy.toast.open({
              duration: 5000,
              message: error ? error.data.error_description : null,
              position: 'is-bottom',
              type: 'is-danger'
            })                            
          })
      },      
      commuteDistanceSummary() {
        if(this.order_commute.length > 0) {

          return this.order_commute.reduce((a, b) => +a + +b.distance, 0) + ' km'
        }
      },      
      workMinutesSummary() {
        if(this.order_work.length > 0) {

          return this.order_work.reduce((a, b) => +a + +b.workers_minutes, 0) + ' minut'
        }
      },
      handleFileChange(file) {
        function getBase64(file) {
          return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = error => reject(error);
          });
        } 
        getBase64(file).then(
          data => {
            let payload = {
              order_id: this.order.id,
              image_stream: data
            }
            this.postOrderImage(payload)
              .then(resp => {
                this.disabledSidebar(false)
                this.files.push(resp.data)
              })
              .catch((error) => {
                this.order = {}
                this.$buefy.toast.open({
                  duration: 5000,
                  message: error.data.error_description,
                  position: 'is-bottom',
                  type: 'is-danger'
                })                            
              }) 
          }
        );              

      },
      getOrderImage() {
        this.getOrderImageCall({ id: this.$route.params.id })
          .then(resp => {
            if(resp.length === 0) {
              this.file = null
              // this.$refs.observer.refs.files.validate()
            }
            this.files = resp
          })
          .catch((error) => {
            this.order_work = {}
            this.$buefy.toast.open({
              duration: 5000,
              message: error.data.error_description,
              position: 'is-bottom',
              type: 'is-danger'
            })                            
          })
      },
      removeOrderImage(id) {
        this.removeOrderImageCall({ id })
          .then(() => {
            this.getOrderImage()
          })
          .catch((error) => {
            this.order_work = {}
            this.$buefy.toast.open({
              duration: 5000,
              message: error.data.error_description,
              position: 'is-bottom',
              type: 'is-danger'
            })                            
          })
      },
      getOrderTechnical() {
        this.getOrderTechnicalCall({ id: this.$route.params.id })
          .then(resp => this.order_technical = resp)
          .catch((error) => {
            this.order_technical = {}
            this.$buefy.toast.open({
              duration: 5000,
              message: error ? error.data.error_description : null,
              position: 'is-bottom',
              type: 'is-danger'
            })                            
          })
      },         
      addOrderTechnical() {
        const isEmpty = !Object.values(this.order_technical_item).every(x => (x !== null && x !== ''));

        if(isEmpty) {
          alert('Uzupełnij wszytkie pola')
        } else {
          let item = {
            ...this.order_technical_item,
            order_id: this.$route.params.id
          }
          this.postOrderTechnicalCall({ item })
            .then((resp) => {
              this.getOrderTechnical()
              this.order_technical.push(resp.data)
              this.$refs.observer.refs.order_technical.validate()
            })
            .catch((error) => {
              this.order_technical = []
              this.$buefy.toast.open({
                duration: 5000,
                message: error ? error.data.error_description : null,
                position: 'is-bottom',
                type: 'is-danger'
              })                            
            })
          this.order_technical_item = {
            firstname: null,
            lastname: null
          }  
        }
      },
      editOrderTechnical(item) {
        this.editOrderTechnicalCall( item )
          .then(() => {
            this.getOrderTechnical()
          })
          .catch((error) => {
            this.order_commute = {}
            this.$buefy.toast.open({
              duration: 5000,
              message: error ? error.data.error_description : null,
              position: 'is-bottom',
              type: 'is-danger'
            })                            
          })
      },      
      removeOrderTechnical(id) {
        this.removeOrderTechnicalCall({ id })
          .then(() => {
            this.getOrderTechnical()
          })
          .catch((error) => {
            this.order_work = {}
            this.$buefy.toast.open({
              duration: 5000,
              message: error ? error.data.error_description : null,
              position: 'is-bottom',
              type: 'is-danger'
            })                            
          })
      }, 
      getServiceType() {
        this.getServiceTypeCall()
          .then((resp) => (this.serviceType = resp))
          .catch((error) => {
            this.serviceType = {};
            this.$buefy.toast.open({
              duration: 5000,
              message: error.data.error_description,
              position: "is-bottom",
              type: "is-danger",
            });
          });
      },                                 
    },
    mounted() {
      this.disabledSidebar(true)
      //  && Object.keys(this.order).length === 0 && this.order.constructor === Object
      if(this.isNew && Object.keys(this.customer).length === 0 && Object.keys(this.device).length === 0 && Object.keys(this.operator).length === 0) {
        this.$store.commit('orders/setOrder', {})
        this.$store.commit('orders/setSelectACustomer', false)
        this.$store.commit('orders/setCustomer', {})
        this.$store.commit('orders/setSelectATechnician', false)
        this.$store.commit('orders/setTechnician', {})
        this.$store.commit('orders/setSelectAOperator', false)
        this.$store.commit('orders/setOperator', {})
        this.$store.commit('orders/setSelectADevice', false)
        this.$store.commit('orders/setDevice', {})
      }
      if(this.order.status_id === 30 || this.order.status_id === 40) {
        this.getCommuteItems()
        this.getWorkItems()
        this.getOrderImage()      
        this.getOrderTechnical()
  
      }  
      this.getServiceType()
      this.getWarrantyStatus()
      // window.addEventListener('online', this.onchange);
      // window.addEventListener('offline', this.onchange);
      this.onchange();      
      if(this.order.is_warranty) {
        this.isWarrantyDisabled = true
      }    
      if(this.isNew) {
        this.order.is_warranty = false
        this.isWarrantyDisabled = true
      }
    },
    beforeDestroy() {
      // window.removeEventListener('online', this.onchange);
      // window.removeEventListener('offline', this.onchange);
    },    
  }
</script>

<style lang="scss" scoped>
  .select {
    width: 100%;
    border-radius: 4px;
    border: none;
    font-size: 16px;
    color: #000;
    font-weight: 600;
    font-family: "Source Sans Pro", sans-serif;
    padding-left: 8px;
  }
  .is-info {
    margin-top: 1rem;
    background-color: #3e8ed0;
    color: #fff;
    padding: 1rem;
  }
</style>
